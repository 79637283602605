import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.showDisclaimer),callback:function ($$v) {_vm.showDisclaimer=$$v},expression:"showDisclaimer"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v("Disclaimer")]),_c(VCardText,[_c('div',{staticClass:"text-h6 pa-8"},[_c('h5',[_vm._v(" We aim to have accurate information. However, there may be data here that's not up-to-date. All of our data came from public internet. We'd really appreciate it that if you find inaccurate data, you'd click on the corresponding \"Report\" option for that team and let us know. Before taking any actions, it would be optimal to reach out to the coach and get confirmation. ")]),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.okDisclaimer}},[_vm._v("OK")])],1)],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }