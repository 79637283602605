<template>
  <homepage />
</template>

<script>
  import Homepage from '../components/Homepage'

  export default {
    name: 'Home',

    components: {
      Homepage,
    },
  }
</script>
