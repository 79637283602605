<template>
  <v-row justify="center">
    <v-dialog v-model="showAddTeam" persistent max-width="400px">
      <v-overlay v-if="showProgress">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-form
          @submit.prevent.stop="addTeam()"
          ref="form-addTeam"
          autocomplete="off"
        >
          <v-toolbar color="primary" dark>Add New Team</v-toolbar>
          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="coach"
                    label="Coach Name *"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Team Name *"
                    @change="resetErrorMsg"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-select
                    v-model="selectedBYear"
                    :items="byears"
                    label="Birth Year *"
                    hint="Automatically moves up team's age group..."
                    persistent-hint
                    required
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-model="selectedSport"
                    :items="sportsSelected"
                    label="Sport *"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-model="selectedAgeGroup"
                    :items="ageGroup"
                    :disabled="!selectedSport"
                    label="Age Group *"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-model="selectedClassLevel"
                    :items="classLevel"
                    :disabled="!selectedSport"
                    label="Class"
                  ></v-select>
                  <v-text-field
                    v-model="city"
                    label="City *"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-select
                    v-model="selectedState"
                    :items="states"
                    :disabled="!selectedSport"
                    label="State *"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                  <v-text-field
                    v-model="zip"
                    label="Zip Code *"
                    required
                    :rules="[rules.required]"
                    hint="Will be used to determine your location..."
                    persistent-hint
                  ></v-text-field>
                  <v-text-field
                  class="mt-5"
                    @click="showLink"
                    v-model="teamURL"
                    label="Paste Facebook Team Page Link"
                    hint="Link must start with 'https://www.facebook.com/'..."
                    persistent-hint
                  ></v-text-field>
                  <!-- <v-text-field
                    v-model="tryoutDate"
                    label="Team Tryout Date"
                  ></v-text-field> -->
                  <v-text-field
                    class="my-5"
                    @click="showLink"
                    @change="tryoutDate=null"
                    v-model="tryout"
                    label="Paste Facebook Tryout Link"
                    hint="Link must start with 'https://www.facebook.com/'..."
                    persistent-hint
                  ></v-text-field>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="!tryout"
                        v-model="tryoutDate"
                        label="(Optional) Team Tryout Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="Enter a Tryout Link first before Tryout Date"
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tryoutDate"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>


                  <!-- <v-text-field
                    v-model="pickupDate"
                    label="Pickup Date"
                  ></v-text-field> -->

                  <v-text-field
                    class="my-5"
                    @click="showLink"
                    @change="pickupDate=null"
                    v-model="pickup"
                    label="Paste Facebook Pickup Link"
                    hint="Link must start with 'https://www.facebook.com/'..."
                    persistent-hint
                  ></v-text-field>

                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="!pickup"
                        v-model="pickupDate"
                        label="(Optional) Pickup Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="Enter a Pickup Link first before Pickup Date"
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="pickupDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>

                  <!-- <v-text-field
                    v-model="teamID"
                    label="USSSA Team ID"
                  ></v-text-field> -->
                  <v-alert
                    outlined
                    type="warning"
                    prominent
                    border="left"
                    v-if="errorOnSubmit"
                    dark
                    :color="alertColor"
                    class="mx-3"
                    >{{ errorOnSubmit }}</v-alert
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-btn
                  class="mx-auto blue white--text"
                  rounded
                  text
                  @click="closeForm"
                >
                  Close
                </v-btn>
                <v-btn rounded class="mx-auto blue white--text" type="submit"
                  >{{ addLabel }}
                </v-btn>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLink" max-width="400px">
      <v-card>
        <v-toolbar color="primary" dark>Security Notice</v-toolbar>
        <v-card-text class="pa-5">
          <div class="pa-6">
            <v-card-subtitle>
              In order to minimize the likelihood of anyone putting ill-natured
              links in this platform, it is a requirement to use links from
              Facebook. Paste a Facebook link that starts with
              'https://www.facebook.com/'... If there's a team link needed
              outside of Facebook, please contact us.
            </v-card-subtitle>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="mb-5 mr-6" color="primary" @click="dialogLink = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CircularProgress v-if="showProgress" />
  </v-row>
</template>

<script>
import CircularProgress from "@/components/CircularProgress.vue";
import { mapGetters } from "vuex";
import { API } from "aws-amplify";
import { trackChangesByEmailnAction } from "@/graphql/queries";
import { createMyTeams, createTrackChanges } from "@/graphql/mutations";
export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      numOfAs: 0,
      numOfCs: 0,
      numOfACs: 0,
      alertColor: "",
      linkShown: false,
      dialogLink: false,
      sportAbbr: "",
      showProgress: false,
      addLabel: "Add",
      zip: "",
      showProgress: false,
      ageGroup: [],
      classLevel: [],
      selectedBYear: 0,
      selectedState: "",
      selectedAgeGroup: "",
      selectedClassLevel: "",
      city: "",
      coach: "",
      email: "",
      teamID: "",
      name: "",
      sportsSelected: ["Baseball", "Fastpitch"],
      selectedSport: "",
      selectedLevel: "",
      states: [],
      tryoutDate: "",
      tryout: "",
      pickupDate: "",
      pickup: "",
      teamURL: "",
      showAddTeam: true,
      errorOnSubmit: "",
      alertColor: "",
      rules: {
        required: (value) => !!value || "Required(*)",
      },
    };
  },
  components: { CircularProgress },
  computed: {
    ...mapGetters({
      user: "auth/user",
      bbStates: "auth/bbStates",
      bbAgeGroup: "auth/bbAgeGroup",
      bbClassLevel: "auth/bbClassLevel",
      fpStates: "auth/fpStates",
      fpAgeGroup: "auth/fpAgeGroup",
      fpClassLevel: "auth/fpClassLevel",
      byears: "auth/byears"
    }),
  },
  created() {
    this.restoreFromLocal();
    this.calcYear();
  },
  mounted() {
    this.getNumOfACs();
  },
  methods: {
    async getNumOfACs() {
      var { data } = await API.graphql({
        query: trackChangesByEmailnAction,
        variables: {
          email: this.user.username,
          action: { eq: "A" },
          limits: 1000,
        },
      });
      this.numOfAs = data.trackChangesByEmailnAction.items.length;

      var { data } = await API.graphql({
        query: trackChangesByEmailnAction,
        variables: {
          email: this.user.username,
          action: { eq: "C" },
          limits: 1000,
        },
      });
      this.numOfCs = data.trackChangesByEmailnAction.items.length;

      this.numOfACs = this.numOfAs + this.numOfCs;
    },
    showLink() {
      if (!this.linkShown) {
        this.dialogLink = true;
      }
      this.linkShown = true;
    },
    isItFB(url){
      // Check if links are from facebook
      var expression = /^https:\/\/www\.facebook\.com\//;
      var regex = new RegExp(expression);

      var mexpression = /^https:\/\/m\.facebook\.com\//;
      var mregex = new RegExp(mexpression);

      var fexpression = /^https:\/\/fb\.me\//;
      var fregex = new RegExp(fexpression);

      return (!!url.match(regex) || !!url.match(mregex) || !!url.match(fregex))

    },
    addTeam() {
      this.errorOnSubmit = "";

      if (this.numOfACs >=9) {
        this.errorOnSubmit =
          "Max number of team addition and claims reached... if more is needed, pls contact us...";
        this.alertColor = "red";
        return;
      }

      const links = [this.teamURL, this.tryout, this.pickup].filter((n) => n);

      var matches = [];
      if (links.length > 0) {
        links.forEach((link) => {
          matches.push(this.isItFB(link))
        });

        // if any of the links is not from FB, then throw error
        if (!matches.every(Boolean)) {
          this.alertColor = "error";
          this.errorOnSubmit =
            "Please use a link from Facebook that starts with 'https://www.facebook.com/'";
          return;
        }
      }

      if (
        !this.coach ||
        !this.name ||
        !this.selectedSport ||
        !this.selectedBYear ||
        !this.selectedAgeGroup ||
        !this.city ||
        !this.selectedState ||
        !this.zip
      ) {
        this.errorOnSubmit = "Please fill missing info...";
        this.alertColor = "error";
        if (!this.$refs["form-addTeam"].validate()) return;
      }

      this.save2Local();
      this.add2DDB();
    },
    resetErrorMsg(){
      this.errorOnSubmit = ""
    },
    async trackInvetoryInMyTeams() {
      try {
        await API.graphql({
          query: createMyTeams,
          variables: {
            input: {
              name: this.name,
              class: (
                this.selectedAgeGroup +
                " " +
                (this.selectedClassLevel || "")
              ).trim(),
              email: this.user.username,
              tblName: this.sportAbbr + this.selectedState.toLowerCase() + "23",
              year: this.year,
            },
          },
        });
      } catch (error) {
        this.errorOnSubmit = error.message;
        console.log(error);
      }
    },
    async trackChanges(item) {
      const itemJSON = JSON.parse(item);
      delete(itemJSON.state)
      delete(itemJSON.phoneNumber)
      delete(itemJSON.showPhone)

      try {
        await API.graphql({
          query: createTrackChanges,
          variables: {
            input: {
              ...itemJSON,
              action: "A",
              year: this.year,
              checked: "N"
            },
          },
        });
      } catch (err) {
        console.log("trackingAddition: ", err);
      }
    },
    async add2DDB() {
      this.showProgress = true;
      const url =
        "https://gu3jyneoxywrclg2433hsr66ru0purqy.lambda-url.us-east-1.on.aws/"; // s4t-search-teams-new-item5
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      // Determine DDB tablename
      if (this.selectedSport === "Baseball") {
        this.sportAbbr = "bb";
      } else if (this.selectedSport === "Fastpitch") {
        this.sportAbbr = "fp";
      }

      if(!this.tryout){
        this.tryout = "Tryout Link Here"
      }

      if(!this.selectedClassLevel){
        this.selectedClassLevel = ""
      }
      var raw = JSON.stringify({
        tblName: this.sportAbbr + this.selectedState.toLowerCase() + "23",
        zip: this.zip,
        address: this.city + ", " + this.selectedState,
        name: this.name,
        class: (
          this.selectedAgeGroup +
          " " +
          this.selectedClassLevel
        ).trim(),
        coach: this.coach,
        teamID: this.teamID.toString() || "0",
        email: this.user.username,
        pickup: this.pickup || "",
        pickupDate: this.pickupDate || "",
        tryout: this.tryout || "",
        tryoutDate: this.tryoutDate || "",
        teamURL: this.teamURL || "",
        state: this.selectedState, 
        byear: this.selectedBYear.toString(),
        phoneNumber: this.user.attributes.phone_number || "",
        showPhone: this.user.attributes.nickname || ""
      });

      // console.log(JSON.parse(raw))

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        await fetch(url, requestOptions).then((response) =>
          this.getStatus(response.status, raw)
        );
      } catch (err) {
        console.log(err);
      }
      this.showProgress = false;
    },
    getStatus(status, raw) {
      this.errorOnSubmit = "";
      if (status === 200) {
        // if successful, update myTeams and trackingChanges
        this.trackInvetoryInMyTeams();
        this.trackChanges(raw);
        this.numOfACs++;
        this.addLabel = "Add More"

        this.errorOnSubmit = "Added Successfully";
        this.alertColor = "green";
      } else if(status === 500){
        this.errorOnSubmit = "Sorry... pls use another zip code in the same area..."
        this.alertColor = "red"
        this.showProgress = false
      } else if(status === 504){
        this.errorOnSubmit = "Sorry... Your zip code does not match state..."
        this.alertColor = "red"
        this.showProgress = false
      }else {
        this.alertColor = "error";
        this.errorOnSubmit =
          "Sorry... pls close your other apps and try again... or contact us if it persists...";
      }
    },
    save2Local() {
      const params = {
        coach: this.coach,
        city: this.city,
        state: this.selectedState,
        zip: this.zip,
        teamURL: this.teamURL,
      };
      localStorage.setItem("paramsAddTeamEDH", JSON.stringify(params));
    },
    restoreFromLocal() {
      const paramsUsed = JSON.parse(localStorage.getItem("paramsAddTeamEDH"));
      if (paramsUsed) {
        this.coach = paramsUsed.coach;
        this.city = paramsUsed.city;
        this.selectedState = paramsUsed.state;
        this.zip = paramsUsed.zip;
        this.teamURL = paramsUsed.teamURL;
      }
    },
    closeForm() {
      this.save2Local();
      this.showAddTeam = false;
      this.$router.push("/mteams");
      this.$emit("OK");
    },
    calcYear() {
      const mon = new Date().getMonth() + 1;
      const day = new Date().getDate().toString().padStart(2, "0");
      const yr = new Date().getFullYear().toString().substr(-2);
      this.year = mon + day + yr;
    },
  },
  watch: {
    selectedSport: function () {
      if (this.selectedSport === "Baseball") {
        this.states = this.bbStates;
        this.ageGroup = this.bbAgeGroup;
        this.classLevel = this.bbClassLevel;
      } else if (this.selectedSport === "Fastpitch") {
        this.states = this.fpStates;
        this.ageGroup = this.fpAgeGroup;
        this.classLevel = this.fpClassLevel;
      }
    },
  },
};
</script>
