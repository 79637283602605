<template>
  <v-app>
    <v-system-bar color="blue darken-3"></v-system-bar>
    <v-app-bar color="primary" dark class="flex-grow-0">
      <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title @click="$router.push('/')">
        <span class="font-weight-light white--text">SEARCH</span>
        <span class="bold white--text" style="font-size: 30px">4</span>
        <span class="font-weight-light white--text">TEAMS</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-icon v-if="!user" @click="$router.push('mteams')">
        mdi-account-circle
      </v-icon>
      <v-icon v-if="user" @click="logout">
        mdi-account-cancel
      </v-icon> -->

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon v-if="user">mdi-account-circle</v-icon>
            <v-icon v-if="!user">mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>

        <v-list class="blue darken-3" dark>

          <v-list-item v-if="!user" to="/login" link>
                        <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon><v-list-item-title>Login</v-list-item-title>

          </v-list-item>
          <v-list-item v-if="user" link>
                        <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon><v-list-item-title @click="logout">Logout</v-list-item-title>
          </v-list-item>
          <v-list-item to="/instructions" link>
            <v-list-item-icon>
              <v-icon>mdi-sign-direction</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Instructions</v-list-item-title>
          </v-list-item>
          <v-list-item to="/mteams" link :disabled="!user">
            <v-list-item-icon>
              <v-icon :disabled="!user">mdi-microsoft-teams</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Teams</v-list-item-title>
          </v-list-item>
          <v-list-item to="/addwarning" link :disabled="!user">
            <v-list-item-icon>
              <v-icon :disabled="!user">mdi-account-multiple-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Add Team</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- <v-card v-if="['mteams','mtmanual','mtsearch'].includes($route.name)">
            <v-tabs
                background-color="blue darken-3"
                center-active
                dark
                next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
            >
                <v-tab to="/mteams">My Teams</v-tab>
                <v-tab to="/mtsearch">Add Teams From List</v-tab>
                <v-tab to="/mtmanual">Add Teams Manually</v-tab>

            </v-tabs>
        </v-card> -->

    <v-navigation-drawer
      v-model="drawer"
      class="primary"
      dark
      temporary
      absolute
    >
      <v-list>
        <v-list-item to='/' link v-if="$route.name !== 'home'">
          <v-list-item-icon>
            <v-icon>mdi-magnify-expand</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Search</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          v-for="(item, i) in navList"
          :key="i"
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="bg">
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data: () => ({
    navList: [
      // { text: "Search", icon: "mdi-magnify-expand", route: "/" },
      { text: "Instructions", icon: "mdi-sign-direction", route: "instructions" },
      { text: "Disclaimer", icon: "mdi-alert", route: "disclaimer" },
      { text: "About Us", icon: "mdi-information", route: "aboutus" },
      { text: "Contact Us", icon: "mdi-phone", route: "contactus" },
      {
        text: "Suggestions",
        icon: "mdi-comment-quote-outline",
        route: "suggestion",
      },
    ],
    drawer: false,
    type: "",
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.authAction();
  },
  methods: {
    ...mapActions("auth", ["authAction"]),
    async logout() {
      await this.$store.dispatch("auth/logout");
      if (this.$route.name !== "home") {
        this.$router.push("/");
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>
