<template>
  <v-overlay v-if="showDialog">
    <v-card color="white" max-width="300" class="mx-auto">
      <v-system-bar color="pink darken-2" dark> </v-system-bar>

      <v-app-bar dark color="pink">
        <v-toolbar-title>Warning!</v-toolbar-title>
      </v-app-bar>

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card color="pink darken-2">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title>Avoid Duplicates</v-card-title>

                  <v-card-subtitle>
                    Before adding a new team, pls check to see if your team already exists by searching for it; try different "Class" in case it was incorrectly classified. If it exists, please "Claim" to modify its data. If already exists, adding a new team will create a duplicate and cause confusion for potential parents. See "Instructions" on how to "Claim" an existing team...
                  </v-card-subtitle>
                </div>
              </div>
              <v-btn
                to="/mtmanual"
                class="ml-4 my-5"
                outlined
                rounded
                small
              >
                OK
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    showDialog: true,
  }),
  methods: {
    closeStateWarning() {
      this.showDialog = false
      this.$emit("OK")
    }
  },
};
</script>
