<template>
  <div>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog v-model="dialogConfirmation" persistent max-width="600">
          <v-card>
            <v-toolbar color="primary" dark>Confirm</v-toolbar>
            <v-card-text>
              <div class="text-h6 pa-12">
                {{ msg }}
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn class="mb-5 mr-6" color="primary" @click="closeCancel"
                >Cancel</v-btn
              >
              <v-btn class="mb-5 mr-6" color="primary" @click="goToLink"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-dialog v-model="showProgress">
      <div class="text-center" justify="center" align="center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["itemFilteredTeams"],
  data() {
    return {
      dialogConfirmation: true,
      showProgress: false,
      showCancel: true,
    };
  },
  computed: {
    msg: {
      get() {
        if (this.itemFilteredTeams.selectedInfo === "Team Details") {
          return (this.msg =
            "Redirecting to the team's page...");
        // } else if (this.itemFilteredTeams.selectedInfo === "Directions") {
        //   return (this.msg =
        //     "Another window will now open with directions to a city that the team registered as their location or where they will be holding their tryout; it is not their actual address...");
        } else if (this.itemFilteredTeams.selectedInfo === "Tryout") {
          return (this.msg =
            "Redirecting to a page with tryout information or a tryout signup page...");
        } else if (this.itemFilteredTeams.selectedInfo === "Pickup") {
          return (this.msg =
            "Redirecting to a page with pickup information...");
        } else if (this.itemFilteredTeams.selectedInfo === "Open Practice"){
          return (this.msg =
            "Please reach out to the coach via email/text (if available) or through their website for details...");
        } else {
          // const trimPhone = (this.itemFilteredTeams.phoneNumber.substring(2)).replace(/(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/, '$1$2$3-$4$5$6-$7$8$9$10')
          return (this.msg = 
            "Please use an iPhone or Android device to get in touch with the coach...");
        }
      },
      set() {},
    },
  },
  methods: {
    closeCancel() {
      this.$emit("OK");
    },
    goToLink() {
      var calcURL = ""
      this.showProgress = true;
      if (this.itemFilteredTeams.selectedInfo === "Team Details") {
        // if(this.itemFilteredTeams.teamURL){
          if(this.itemFilteredTeams.teamURL === "0" || this.itemFilteredTeams.teamURL === ""){
            calcURL = "https://dev-next.usssa.com/teams/" + this.itemFilteredTeams.teamID
          } else {
            calcURL = this.itemFilteredTeams.teamURL;
          }
        this.$emit("OK");
        window.open(calcURL);
      } else if (this.itemFilteredTeams.selectedInfo === "Tryout") {
        this.$emit("OK");
        window.open(this.itemFilteredTeams.tryout);
      } else if (this.itemFilteredTeams.selectedInfo === "Pickup") {
        window.open(this.itemFilteredTeams.pickup);
        this.$emit("OK");
      } else {
        this.$emit("OK");
      }
      this.showProgress = false;
    },
  },
};
</script>
