<template>
  <v-overlay v-if="showDialog">
    <v-card color="white" max-width="350" class="mx-auto">

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card class="blue darken-3" dark>
              <div class="d-flex flex-no-wrap justify-space-between mt-3">
                <div class="text-body-1 mx-4 my-4">
                    Since you have not claimed a team from our database or manually added a team, this page
                    will not show any team that you can edit. You can claim a team from the main search page by 
                    clicking "Info" then "Claim" for the corresponding team. You can also add a team manually by 
                    clicking on top right coaches' corner to select "Add Team".
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1 mt-5">
          <v-btn @click="showDialog = false" class="ma-auto" rounded color="black" dark>
            OK
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      showDialog: true,
      errorOnSubmit: "",
      showProgress: false,
    };
  },
  methods: {
  },
};
</script>
