<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog v-model="showAbout" persistent max-width="600">
        <v-card>
          <v-toolbar color="primary" dark>About Us</v-toolbar>
          <v-card-text>
            <div class="text-h6 pa-8">
              <v-card-actions class="justify-end">
                <v-btn color="primary" style="margin-bottom: 20px;" @click="okAbout">OK</v-btn>
              </v-card-actions>
              <h5>
                KylesDev is based in North Texas. Kyle is a high school student
                at Allen High School. He is aspiring to become a webapp
                developer someday. His journey began by creating different
                webapps that others hopefully found useful. Search4Teams.com is
                his latest project. The inspiration for this webapp came
                partially from his younger sister's softball adventures. He
                noticed that there's a need to make it easier to help parents
                find teams for their kids. His search engine provides a
                convenient way to find teams within proximity and which ones are
                having a tryout or looking for a pickup.
              </h5>
              <br>
              <h5>
                Team information is available in the internet but this public
                data is scattered. Search4Teams' main objective is to provide a
                centralize location to easily find teams that are having a
                tryout near you or looking for pickups. Currently, it has data
                for only "Baseball and Fastpitch" in a few states for now. Subsequent releases will
                gradually introduce other sports and states. It is highly
                recommended that before any action is taken, parents should
                reach out to coaches directly.
              </h5>
              <v-card-actions class="justify-end">
                <v-btn color="primary" @click="okAbout">OK</v-btn>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      showAbout: true,
    };
  },
  methods: {
    okAbout() {
      this.showAbout = false;
      this.$router.push('/')
      this.$emit("OK");
    },
  },
};
</script>
