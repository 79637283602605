<template>
  <v-dialog v-model="showDialog">
    <v-card color="white" min-width="300" class="mx-auto">
      <!-- <v-system-bar color="pink darken-2" dark> </v-system-bar>

      <v-app-bar dark color="pink">
        <v-toolbar-title>Claim a Team Warning!</v-toolbar-title>
      </v-app-bar> -->

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card class="blue darken-3" dark>
              <div class="d-flex flex-no-wrap justify-space-between mt-3">
                <div>
                  <v-card-title>Step 1 - Login or Register</v-card-title>
                  <v-card-subtitle>
                    Your email will be seen by parents. You can "Allow" potential parents to see your phone number during registration.
                  </v-card-subtitle>
                  <v-card-title>Step 2 - Claim Team</v-card-title>
                  <v-card-subtitle>
                    By claiming your team, you're able to edit your team's data. Once logged in, search for your team. If not found, pls search using a different "Class" in case it was classified incorrectly. Once 
                    found, click on the corresponding "Info" button and then click "Claim".
                    If you don't see the "Claim" option, it may have been claimed already OR you need to click "Search" again to refresh the screen. If 
                    there was an illegal claim, please contact us.
                  </v-card-subtitle>
                  <v-card-title>Step 3 - Add Team</v-card-title>
                  <v-card-subtitle>
                    Please make sure that the team you want to add is not already in the system or else there will be duplicates
                    and cause parent confusion. If you don't find your team, please click "Add Team" in the top right coaches' corner to add your team.
                  </v-card-subtitle>
                  <v-card-title>Step 4 - Edit Team</v-card-title>
                  <v-card-subtitle>
                    If you want to see your teams
                    , click "My Teams" in the top right coaches' corner. On this page, you can modify, delete, and unclaim any team's data 
                    using the "Actions" column of the table.
                  </v-card-subtitle>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1 mt-5">
          <v-btn to="/" class="ma-auto" rounded color="black" dark>
            OK
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showDialog: true,
      errorOnSubmit: "",
      showProgress: false,
    };
  },
  methods: {
  },
};
</script>
