import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue'
import aws_exports from './aws-exports';
// import {
//   applyPolyfills,
//   defineCustomElements,
// } from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
// applyPolyfills().then(() => {
//   defineCustomElements(window);
// });
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
//   render: function (h) { return h(App) }
// }).$mount('#app')
render: h => h(App)
}).$mount('#app')

