<template>
  <div>
    <v-card v-if="isHandheldDev" max-width="800" class="mx-auto mt-2">
      <v-container class="mb-8">
        <v-row dense>
          <v-col cols="12">
            <v-card class="blue darken-3" dark>
              <v-card-subtitle>
                Turn your phone sideways for a
                better landscape view. Once sideways, sort the table by clicking
                on the column header name.
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-app>
      <v-data-table
        :headers="headers"
        :items="myTeams"
        :items-per-page="-1"
        hide-default-footer
        sort-by="name"
        class="elevation-1"
      >
        <template v-slot:item.teamURL="{ item }">
          <div class="text-truncate" style="max-width: 250px">
            {{ item.teamURL }}
          </div>
        </template>
        <template v-slot:item.tryout="{ item }">
          <div class="text-truncate" style="max-width: 250px">
            {{ item.tryout }}
          </div>
        </template>
        <template v-slot:item.pickup="{ item }">
          <div class="text-truncate" style="max-width: 250px">
            {{ item.pickup }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  color="primary"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="deleteItem(item)"
                  color="error"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ml-2"
                  color="purple"
                  @click="UnClaimItem(item)"
                >
                  mdi-pin-off
                </v-icon>
              </template>
              <span>Unclaim</span>
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>My Teams</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New Item
            </v-btn>
          </template> -->
              <v-card>
                <v-toolbar color="primary" dark>Edit Team</v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Team Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          :items="byears"
                          v-model="editedItem.byear"
                          label="Birth Year"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.coach"
                          label="Coach"
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          @click="showLocation"
                          v-model="editedItem.address"
                          label="Location"
                          hint="Will not update Geolocation... if need to, pls contact us..."
                          persistent-hint
                        ></v-text-field>
                      </v-col> -->
                      <!-- <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.class"
                          label="Class"
                        ></v-text-field>
                      </v-col> -->
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          :items="ageGroup"
                          v-model="editedItem.ageGroup"
                          label="Age Group"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          :items="classLevel"
                          v-model="editedItem.classLevel"
                          label="Level"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          @click="showLink"
                          class="text-truncate"
                          v-model="editedItem.teamURL"
                          label="Paste Facebook Team Page Link"
                          hint="Link must start with 'https://www.facebook.com/'..."
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          @input="resetTryoutDate"
                          v-model="editedItem.tryout"
                          label="Paste Facebook Tryout Link"
                          hint="Link must start with 'https://www.facebook.com/'..."
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          @input="resetPickupDate"
                          v-model="editedItem.pickup"
                          label="Paste Facebook Pickup Link"
                          hint="Link must start with 'https://www.facebook.com/'..."
                          persistent-hint
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.tryoutDate"
                          label="Tryout Date"
                          hint="Please use MM/DD/YY format..."
                          persistent-hint
                        ></v-text-field>
                      </v-col> -->

                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :disabled="!editedItem.tryout"
                            v-model="editedItem.tryoutDate"
                            label="Team Tryout Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hint="Enter a Tryout Link first before Tryout Date"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.tryoutDate"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>

                      <!-- <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.pickupDate"
                          label="Pickup Date"
                          hint="Please use MM/DD/YY format..."
                          persistent-hint
                        ></v-text-field>
                      </v-col> -->

                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :disabled="!editedItem.pickup"
                            v-model="editedItem.pickupDate"
                            label="Pickup Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hint="Enter a Pickup Link first before Pickup Date"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.pickupDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-row justify="center">
                  <v-alert
                    outlined
                    type="warning"
                    prominent
                    border="left"
                    v-if="errorOnSubmit"
                    dark
                    color="error"
                    class="mx-10 mb-4 mt-4"
                    >{{ errorOnSubmit }}
                  </v-alert>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" rounded dark @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="primary" rounded dark @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6"
                  >Continue to delete?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogUnClaim" max-width="500px">
              <v-card>
                <v-card-title class="text-h6"
                  >Continue to unclaim this team?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogUnClaim = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="UnclaimItemConfirm"
                    >YES</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogLink" max-width="400px">
              <v-card>
                <v-toolbar color="primary" dark>Security Notice</v-toolbar>
                <v-card-text class="pa-5">
                  <div class="pa-6">
                    <v-card-subtitle>
                      In order to minimize the likelihood of anyone putting
                      ill-natured links in this platform, it is a requirement to
                      use links from Facebook. Paste a Facebook link that starts
                      with 'https://www.facebook.com/'... If there's a team link
                      needed outside of Facebook, please contact us.
                    </v-card-subtitle>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    class="mb-5 mr-6"
                    color="primary"
                    @click="dialogLink = false"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogLocation" max-width="400px">
              <v-card>
                <v-toolbar color="primary" dark>Location Notice</v-toolbar>
                <v-card-text class="pa-5">
                  <div class="pa-6">
                    <v-card-subtitle>
                      Changing the city and state will not change the
                      geolocation already recorded for this team. The
                      geolocation is used to determine a searchers proximity to
                      your team. Geolocation cannot be updated. If there's a
                      change in city and state, please delete the team and
                      recreate it. Please note that when recreating, the zip
                      code determines the geolocation.
                    </v-card-subtitle>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    class="mb-5 mr-6"
                    color="primary"
                    @click="dialogLocation = false"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelMax" max-width="400px">
              <v-card>
                <v-toolbar color="error" dark>Max Deletion Reached</v-toolbar>
                <v-card-text class="pa-5">
                  <div class="pa-6">
                    <v-card-subtitle>
                      In order to minimize the likelihood of anyone deleting
                      data with negative intentions, we placed a maximum
                      deletion of 10. We apologize for this incovenience. If
                      more deletion is needed, pls contact us.
                    </v-card-subtitle>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    class="mb-5 mr-6"
                    color="error"
                    @click="dialogDelMax = false"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-app>
    <ModalZeroTeams v-if="dialogZeroTeams" />
    <CircularProgress v-if="showProgress" />
  </div>
</template>
<script>
import { API } from "aws-amplify";
import {
  myTeamsByEmailAndTblName,
  trackChangesByEmailnAction,
} from "@/graphql/queries";
import { createTrackChanges, deleteMyTeams } from "@/graphql/mutations";
import CircularProgress from "@/components/CircularProgress.vue";
import ModalZeroTeams from "@/components/ModalZeroTeams.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  data: () => ({
    enableChkUrl: true,
    isHandheldDev: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    ageGroup: [],
    classLevel: [],
    states: [],
    dialogZeroTeams: false,
    dialogDelMax: false,
    numOfDels: 0,
    linkShown: false,
    locationShown: false,
    dialogLink: false,
    dialogLocation: false,
    dialogUnClaim: false,
    myTeamsRaw: [],
    errorOnSubmit: "",
    year: "",
    showProgress: false,
    selectedIDs: [],
    selectedSport: "",
    myTeams: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Birth Yr", value: "byear" },
      { text: "Class", value: "class" },
      { text: "Location", value: "address" },
      { text: "Coach", value: "coach" },
      // { text: "Email", value: "email" },
      { text: "Team Page", value: "teamURL" },
      { text: "Tryout Link", value: "tryout" },
      { text: "Pickup Link", value: "pickup" },
      { text: "Tryout Date", value: "tryoutDate" },
      { text: "Pickup Date", value: "pickupDate" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      class: "",
      location: "",
      coach: "",
      teamURL: "",
      tryout: "",
      tryoutDate: "",
      pickup: "",
      pickupDate: "",
      ageGroup: "",
      classLevel: "",
      byear: 0,
    },
  }),
  components: { CircularProgress, ModalZeroTeams },
  computed: {
    ...mapGetters({
      user: "auth/user",
      bbStates: "auth/bbStates",
      bbAgeGroup: "auth/bbAgeGroup",
      bbClassLevel: "auth/bbClassLevel",
      fpStates: "auth/fpStates",
      fpAgeGroup: "auth/fpAgeGroup",
      fpClassLevel: "auth/fpClassLevel",
      byears: "auth/byears",
      os: "auth/os"
    }),
  },
  created(){
    this.getOS()
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getIDs();
    this.calcYear();
    this.getNumOfDels();
    if(this.os === 'iOS' || this.os === 'Android'){
      this.isHandheldDev = true
    }
  },

  methods: {
    ...mapActions({
      findOSvue: "auth/findOS",
    }),
    getOS() {
      this.findOSvue()
    },
    resetTryoutDate() {
      this.showLink();
      this.editedItem.tryoutDate = "";
    },
    resetPickupDate() {
      this.showLink();
      this.editedItem.pickupDate = "";
    },
    async getNumOfDels() {
      //pull all records from db
      const { data } = await API.graphql({
        query: trackChangesByEmailnAction,
        variables: {
          email: this.user.username,
          action: { eq: "D" },
          limits: 1000,
        },
      });
      this.numOfDels = data.trackChangesByEmailnAction.items.length;
    },
    showLink() {
      if (!this.linkShown) {
        this.dialogLink = true;
      }
      this.linkShown = true;
    },
    showLocation() {
      if (!this.locationShown) {
        this.dialogLocation = true;
      }
      this.locationShown = true;
    },
    async UncItem(itemUnClaim) {
      this.errorOnSubmit = "";
      this.showProgress = true;

      const itemUnc = {
        tblName: itemUnClaim.tblName,
        rangeKey: itemUnClaim.rangeKey,
        hashKey: itemUnClaim.hashKey,
      };

      const url =
        "https://zzg66ebtinp5al26z7jf3fxtra0jkysj.lambda-url.us-east-1.on.aws/"; // s4t-search-teams-unclaim
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(itemUnc);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        await fetch(url, requestOptions)
          .then((response) => this.getStatus(response.status))
          .then(this.delFromMyTeams(itemUnClaim));
      } catch (err) {
        this.errorOnSubmit = err.message;
      }
      this.showProgress = false;
    },
    async delFromMyTeams(itemDel) {
      // Find Index of To be Delete Item in My Teams
      const indexToBeDel = this.myTeamsRaw
        .map((e) => e.name + e.class + e.tblName)
        .indexOf(itemDel.name + itemDel.class + itemDel.tblName);

      const deleteInput = {
        id: this.myTeamsRaw[indexToBeDel].id,
      };

      try {
        //delete from db
        await API.graphql({
          query: deleteMyTeams,
          variables: { input: deleteInput },
        });
      } catch (err) {
        console.log(err);
      }
    },
    delItem(itemDel) {
      this.showProgress = true;
      this.getNumOfDels();
      const lat = JSON.parse(itemDel.geoJson).coordinates[1];
      const lng = JSON.parse(itemDel.geoJson).coordinates[0];

      const delItemReq = {
        email: itemDel.email,
        tblName: itemDel.tblName,
        rangeKey: itemDel.rangeKey,
        lat: lat,
        lng: lng,
      };
      this.trackingChanges(itemDel, "D");
      this.delFromMyTeams(itemDel);
      this.delViaAPI(delItemReq);
    },
    async delViaAPI(itemDel) {
      this.errorOnSubmit = "";
      const url =
        "https://hdipf43sb32j4yy32tcqyig4ui0fdtrs.lambda-url.us-east-1.on.aws/"; // s4t-search-teams-delete-item
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(itemDel);

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        // redirect: "follow",
      };

      try {
        await fetch(url, requestOptions).then((response) =>
          this.getStatus(response.status)
        );
      } catch (err) {
        this.errorOnSubmit = err.message;
      }

      this.showProgress = false;
    },
    async trackingChanges(item, action) {
      try {
        await API.graphql({
          query: createTrackChanges,
          variables: {
            input: {
              tblName: item.tblName,
              lat: JSON.parse(item.geoJson).coordinates[1],
              lng: JSON.parse(item.geoJson).coordinates[0],
              address: item.address,
              name: item.name,
              class: item.class,
              coach: item.coach,
              teamID: item.teamID,
              email: this.user.username,
              pickup: item.pickup,
              pickupDate: item.pickupDate,
              tryout: item.tryout,
              tryoutDate: item.tryoutDate,
              teamURL: item.teamURL,
              year: this.year,
              action: action,
              checked: "N",
              byear: item.byear,
              zip: item.zip
            },
          },
        });
      } catch (err) {
        console.log("trackingChanges: ", err);
      }
    },
    saveChanges(itemUpd) {
      this.showProgress = true;
      const lat = JSON.parse(itemUpd.geoJson).coordinates[1];
      const lng = JSON.parse(itemUpd.geoJson).coordinates[0];

      const updateItem = {
        tblName: itemUpd.tblName,
        rangeKey: itemUpd.rangeKey,
        lat: lat,
        lng: lng,
        address: itemUpd.address,
        name: itemUpd.name,
        class: itemUpd.class,
        coach: itemUpd.coach,
        teamID: itemUpd.teamID.toString(),
        email: this.user.username,
        pickup: itemUpd.pickup,
        pickupDate: itemUpd.pickupDate,
        tryout: itemUpd.tryout,
        tryoutDate: itemUpd.tryoutDate,
        teamURL: itemUpd.teamURL,
        year: this.year,
        zip: itemUpd.zip || "",
        byear: (itemUpd.byear || "0").toString(),
        phoneNumber: itemUpd.phoneNumber || "",
        showPhone: itemUpd.showPhone || "",
      };

      // console.log(updateItem.zip, updateItem.byear);

      const dataJSON = JSON.parse(JSON.stringify(updateItem));
      // console.log(dataJSON);
      this.updateData(dataJSON);
      this.trackingChanges(itemUpd, "U");
    },
    async updateData(item) {
      this.errorOnSubmit = "";
      const url =
        "https://tfkqgfex53cac5sn4ntccq2rie0apxqy.lambda-url.us-east-1.on.aws/"; // s4t-search-teams-update-item3
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(item);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        await fetch(url, requestOptions).then((response) =>
          this.getStatus(response.status)
        );
      } catch (err) {
        this.errorOnSubmit = err.message;
        console.log(err.message);
      }
      this.showProgress = false;
    },
    async getIDs() {
      this.showProgress = true;
      //pull all records from db
      const { data } = await API.graphql({
        query: myTeamsByEmailAndTblName,
        variables: { email: this.user.username, limits: 1000 },
      });

      if (data.myTeamsByEmailAndTblName.items.length === 0) {
        this.showProgress = false;
        this.dialogZeroTeams = true;
      }
      var tblList = [];
      data.myTeamsByEmailAndTblName.items.forEach((item) => {
        this.myTeamsRaw.push(item);
        tblList.push(item.tblName);
      });
      const uniqueTables = [...new Set(tblList)];
      uniqueTables.forEach((tblName) => {
        this.getData(tblName);
      });
    },
    getStatus(status) {
      this.errorOnSubmit = "";
      if (status === 200) {
        // console.log("Success")
      } else {
        this.alertColor = "error";
        this.errorOnSubmit =
          "Sorry... pls close your other apps and try again... or contact us if it persists...";
      }
    },
    async getData(tblName) {
      const url =
        "https://ou7hjrc542wmxwfdrmy6di6mru0hgvuf.lambda-url.us-east-1.on.aws/"; // s4t-search-teams-getByEmail
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        tblName: tblName,
        email: this.user.username,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        await fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => this.processNewData(result, tblName));
      } catch (err) {
        console.log(err);
      }
    },
    processNewData(items, tblName) {
      items.forEach((item) => {
        // this.myTeams.push({
        //   ...item,
        //   tblName: tblName,
        // });
        this.myTeams.push({
          tblName: tblName,
          rangeKey: item.rangeKey,
          geoJson: item.geoJson,
          hashKey: item.hashKey,
          address: item.address,
          class: item.class,
          coach: item.coach,
          email: item.email,
          name: item.name,
          pickup: item.pickup,
          pickupDate: item.pickupDate,
          teamID: item.teamID,
          teamURL: item.teamURL === "0" ? "" : item.teamURL,
          tryout: item.tryout,
          tryoutDate: item.tryoutDate,
          year: item.year,
          zip: item.zip,
          byear: item.byear,
          phoneNumber: item.phoneNumber,
          showPhone: item.showPhone,
        });
      });
      this.showProgress = false;
    },
    calcYear() {
      const mon = new Date().getMonth() + 1;
      const day = new Date().getDate().toString().padStart(2, "0");
      const yr = new Date().getFullYear().toString().substr(-2);
      this.year = mon + day + yr;
    },
    editItem(item) {
      this.errorOnSubmit = "";

      // determine which sport
      this.selectedSport = item.tblName.substr(0, 2);

      //determine AgeGroup and Level
      const pre = item.class.split(" ");
      item.ageGroup = pre[0];
      item.classLevel = item.class.split(" ").slice(1).join(" ");

      this.editedIndex = this.myTeams.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;

      // if there's already a teamURL, disable the need to check any URLs
      if(this.editedItem.teamURL){
        this.enableChkUrl = false
      }
    },
    deleteItem(item) {
      if (this.numOfDels >= 9) {
        this.dialogDelMax = true;
      } else {
        this.editedIndex = this.myTeams.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },
    deleteItemConfirm() {
      const itemDel = JSON.parse(
        JSON.stringify(this.myTeams[this.editedIndex], this.editedItem)
      );
      this.numOfDels++;
      this.delItem(itemDel);

      this.myTeams.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    UnClaimItem(item) {
      this.editedIndex = this.myTeams.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogUnClaim = true;
    },
    UnclaimItemConfirm() {
      const itemUnClaim = JSON.parse(
        JSON.stringify(this.myTeams[this.editedIndex], this.editedItem)
      );
      this.UncItem(itemUnClaim);

      this.myTeams.splice(this.editedIndex, 1);
      this.dialogUnClaim = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //reconstruct class
      if (!this.editedItem.classLevel) {
        this.editedItem.classLevel = "";
      }

      this.editedItem.class = (
        this.editedItem.ageGroup +
        " " +
        this.editedItem.classLevel
      ).trim();
      const itemUpd = JSON.parse(JSON.stringify(this.editedItem));

      // Check if links are from facebook
      var expression = /^https:\/\/www\.facebook\.com\//;
      var regex = new RegExp(expression);

      var mexpression = /^https:\/\/m\.facebook\.com\//;
      var mregex = new RegExp(mexpression);

      const links = [itemUpd.teamURL, itemUpd.tryout, itemUpd.pickup].filter(
        (n) => n
      );

      var matches = [];
      
      if (links.length > 0) {
        links.forEach((link) => {
          matches.push(!!link.match(regex) || !!link.match(mregex));
        });

        // if url needs to checked and if any of the links is not FB, then throw error
        if (!matches.every(Boolean) && this.enableChkUrl) {
          this.errorOnSubmit =
            "Please use a link from Facebook that starts with 'https://www.facebook.com/'";
          return;
        }
        // else save
        else {
          this.saveChanges(itemUpd);
          Object.assign(this.myTeams[this.editedIndex], this.editedItem);
          this.close();
        }
      } else {
        this.saveChanges(itemUpd);
        Object.assign(this.myTeams[this.editedIndex], this.editedItem);
        this.close();
      }
      this.enableChkUrl = true
    },
  },
  watch: {
    selectedSport: function () {
      if (this.selectedSport === "bb") {
        this.states = this.bbStates;
        this.ageGroup = this.bbAgeGroup;
        this.classLevel = this.bbClassLevel;
      } else if (this.selectedSport === "fp") {
        this.states = this.fpStates;
        this.ageGroup = this.fpAgeGroup;
        this.classLevel = this.fpClassLevel;
      }
    },
  },
};
</script>
