import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.showAbout),callback:function ($$v) {_vm.showAbout=$$v},expression:"showAbout"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v("About Us")]),_c(VCardText,[_c('div',{staticClass:"text-h6 pa-8"},[_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticStyle:{"margin-bottom":"20px"},attrs:{"color":"primary"},on:{"click":_vm.okAbout}},[_vm._v("OK")])],1),_c('h5',[_vm._v(" KylesDev is based in North Texas. Kyle is a high school student at Allen High School. He is aspiring to become a webapp developer someday. His journey began by creating different webapps that others hopefully found useful. Search4Teams.com is his latest project. The inspiration for this webapp came partially from his younger sister's softball adventures. He noticed that there's a need to make it easier to help parents find teams for their kids. His search engine provides a convenient way to find teams within proximity and which ones are having a tryout or looking for a pickup. ")]),_c('br'),_c('h5',[_vm._v(" Team information is available in the internet but this public data is scattered. Search4Teams' main objective is to provide a centralize location to easily find teams that are having a tryout near you or looking for pickups. Currently, it has data for only \"Baseball and Fastpitch\" in a few states for now. Subsequent releases will gradually introduce other sports and states. It is highly recommended that before any action is taken, parents should reach out to coaches directly. ")]),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.okAbout}},[_vm._v("OK")])],1)],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }