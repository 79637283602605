<template>
  <v-row justify="center">
    <v-dialog v-model="showSuggestion" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>Suggestion Box</v-toolbar>
        <v-card-text>
          <v-container>
            <v-textarea
              v-model="message"
              auto-grow
              filled
              color="deep-purple"
              label="Suggestion(s)"
              rows="4"
            ></v-textarea>
            <v-row no-gutters>
              <v-col cols="10">
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  label="Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" class="mx-auto" style="margin-top: -8px">
          <v-radio-group v-model="type" row>
            <v-radio value="Parent" label="Parent"></v-radio>
            <v-radio value="Coach" label="Coach"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row class="mb-5" justify="center" no-gutters>
          <span
            v-if="errorOnSubmit"
            align="center"
            class="font-weight-bold red--text"
            >{{ errorOnSubmit }}</span
          >
          <p align="center" class="font-weight-bold red--text">
            {{ emailError }}
          </p>
        </v-row>
        <v-row>
          <v-btn class="mx-auto blue white--text" text @click="closeSuggestion"> Close </v-btn>
          <v-btn class="mx-auto blue white--text" text @click="sendEmail"> Send </v-btn>
        </v-row>
        <v-row>
        <p class="mx-auto mt-2 font-weight-bold red--text">
          {{ emailError }}
        </p>
        </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CircularProgress v-if="showProgress" />
  </v-row>
</template>

<script>
import emailjs from "emailjs-com";
import CircularProgress from "@/components/CircularProgress.vue";
export default {
  data() {
    return {
      errorOnSubmit: "",
      showProgress: false,
      name: "",
      email: "",
      message: "",
      emailError: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 40 || "Name must be less than 40 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      showSuggestion: true,
      type: ""
    };
  },
  components: { CircularProgress },
  methods: {
    async sendEmail(teamName) {
      if(!this.email){
        this.errorOnSubmit = "Please provide an email address..."
        return;
      }
      this.showProgress = true;
      this.emailError = "";
      try {
        const response = await emailjs.send(
          "service_l53ch69",
          "template_z4ae4n3",
          {
            name: this.name,
            message: this.message,
            email: this.email,
            type: this.type
          },
          "o90X7NobdeU4umK9U"
        );        
        this.$router.push('/')
        this.showProgress = false;
        this.showSuggestion = false;
        this.$emit("OK");

        // console.log(response.status, response.text)
        
      } catch (error) {
        this.emailError = error;
      }
    },
    closeSuggestion() {
      this.showSuggestion = false;
      this.$router.push('/')
      this.$emit("OK");
    }
  },
};
</script>
