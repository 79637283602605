import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":""},model:{value:(_vm.dialogModel),callback:function ($$v) {_vm.dialogModel=$$v},expression:"dialogModel"}},[_c(VSimpleTable,{attrs:{"dark":"","fixed-header":"","height":"600px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left font-weight-bold subtitle-1 blue darken-3 white--text"},[_c(VRow,{staticClass:"ml-2 my-6"},[_c(VCol,[_c(VRow,[_vm._v(" TFL Summer Championship ")]),_c(VRow,[_vm._v(" Text us at 469-525-0450 ")])],1)],1)],1),_c('th',{staticClass:"text-left font-weight-bold subtitle-1 blue darken-3 white--text"})])]),_c('tbody',_vm._l((_vm.teams),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c(VRow,{staticClass:"my-4"},[_c(VCol,[(item.email && item.email.substring(0,4) !== 'UZU-')?_c('p',{staticClass:"font-weight-medium my-auto blue--text darken-5"},[_vm._v(" CLAIMED ")]):_vm._e(),(!item.email || item.email.substring(0,4) === 'UZU-')?_c(VBtn,{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.execClaim(item)}}},[_vm._v("Claim")]):_vm._e()],1)],1)],1)])}),0)]},proxy:true}])}),(_vm.showLoginClaim)?_c('LoginClaim',{on:{"registerClaim":_vm.funcRegister,"closeLogin":_vm.funcCloseLogin,"ccloseLogin":function($event){_vm.showLoginClaim=false}}}):_vm._e(),(_vm.showRegisterClaim)?_c('RegisterClaim',{on:{"closeRegister":_vm.funcCloseLogin,"back":_vm.backRegister,"ccloseRegister":function($event){_vm.showRegisterClaim=false}}}):_vm._e(),(_vm.showPostClaim)?_c('ModalPostClaim'):_vm._e(),(_vm.showClaim)?_c('ModalClaimNotice',{attrs:{"itemUpd":_vm.itemFilteredTeams},on:{"OK":function($event){return _vm.postClaim(_vm.itemFilteredTeams)},"Decline":function($event){_vm.showClaim = false}}}):_vm._e(),(_vm.showPreClaim)?_c('ModalPreClaim'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }