<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog v-model="showContact" persistent max-width="600">
        <v-card>
          <v-toolbar color="primary" dark>Contact Us</v-toolbar>
          <v-card-text>
            <div class="text-h6 pa-12">
              <h3>Contact KylesDev</h3>
              <br>
              <v-icon>mdi-google-maps</v-icon>
              <p>Location: Allen TX</p>
              <v-icon>mdi-phone</v-icon>
              <p>Text: 469-525-0450</p>
              <v-card-actions class="justify-end">
                <v-btn color="primary" @click="okContact">OK</v-btn>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      showContact: true,
    };
  },
  methods: {
    okContact() {
      this.showContact = false;
      this.$router.push('/')
      this.$emit("OK");
    },
  },
};
</script>
