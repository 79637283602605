import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth/auth'
// import { teams } from './teams/teams';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    // teams
  }
})
