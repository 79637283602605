/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMyTeams = /* GraphQL */ `
  mutation CreateMyTeams(
    $input: CreateMyTeamsInput!
    $condition: ModelMyTeamsConditionInput
  ) {
    createMyTeams(input: $input, condition: $condition) {
      id
      name
      class
      email
      tblName
      year
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMyTeams = /* GraphQL */ `
  mutation UpdateMyTeams(
    $input: UpdateMyTeamsInput!
    $condition: ModelMyTeamsConditionInput
  ) {
    updateMyTeams(input: $input, condition: $condition) {
      id
      name
      class
      email
      tblName
      year
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMyTeams = /* GraphQL */ `
  mutation DeleteMyTeams(
    $input: DeleteMyTeamsInput!
    $condition: ModelMyTeamsConditionInput
  ) {
    deleteMyTeams(input: $input, condition: $condition) {
      id
      name
      class
      email
      tblName
      year
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTrackChanges = /* GraphQL */ `
  mutation CreateTrackChanges(
    $input: CreateTrackChangesInput!
    $condition: ModelTrackChangesConditionInput
  ) {
    createTrackChanges(input: $input, condition: $condition) {
      id
      email
      action
      tblName
      year
      lat
      lng
      name
      address
      class
      coach
      teamID
      tryout
      tryoutDate
      pickup
      pickupDate
      teamURL
      zip
      checked
      byear
      misc
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTrackChanges = /* GraphQL */ `
  mutation UpdateTrackChanges(
    $input: UpdateTrackChangesInput!
    $condition: ModelTrackChangesConditionInput
  ) {
    updateTrackChanges(input: $input, condition: $condition) {
      id
      email
      action
      tblName
      year
      lat
      lng
      name
      address
      class
      coach
      teamID
      tryout
      tryoutDate
      pickup
      pickupDate
      teamURL
      zip
      checked
      byear
      misc
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTrackChanges = /* GraphQL */ `
  mutation DeleteTrackChanges(
    $input: DeleteTrackChangesInput!
    $condition: ModelTrackChangesConditionInput
  ) {
    deleteTrackChanges(input: $input, condition: $condition) {
      id
      email
      action
      tblName
      year
      lat
      lng
      name
      address
      class
      coach
      teamID
      tryout
      tryoutDate
      pickup
      pickupDate
      teamURL
      zip
      checked
      byear
      misc
      createdAt
      updatedAt
      owner
    }
  }
`;
