<template>
  <div>
    <v-dialog v-model="dialogRegister" persistent max-width="400px">
      <v-card>
        <!-- <v-system-bar color="blue darken-3" dark> </v-system-bar> -->
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Coach Registration</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="$emit('ccloseRegister')">mdi-close-circle-outline</v-icon>
        </v-toolbar>
        <v-form
          @submit.prevent.stop="register()"
          ref="form-register"
          autocomplete="off"
        >
          <v-row class="mt-6 mb-4" justify="center" align="center" no-gutters>
            <v-col md="11">
              <v-text-field
                class="mx-10"
                v-model="loginEmail"
                :rules="emailRules"
                label="Email"
                hint="Will be used by parents to contact coaches..."
                persistent-hint
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-col md="11">
              <v-text-field
                class="mx-10"
                v-model="loginPassword"
                :rules="passwordRules"
                type="password"
                label="Password"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-col md="11">
              <v-text-field
                class="mx-10"
                :rules="phoneNumberRules"
                v-model="phoneNumber"
                label="Phone Number"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row style="margin-top: -20px" no-gutters>
            <v-col class='d-flex justify-center'>
              <v-checkbox
                v-model="checked"
                label="Allow parents to see my phone number"
                value="Y"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            v-if="loginError"
            dark
            color="error"
            class="mx-10"
            >{{ loginError }}</v-alert
          >
          <v-row class="mt-4" justify="center" no-gutters>
            <v-btn
              dark
              type="submit"
              class="pink accent-3 white--text mb-4"
              elevation="10"
              rounded
              >Register<v-icon color="white">mdi-account-plus</v-icon></v-btn
            >
            <v-btn
              @click="$emit('back')"
              color="black"
              dark
              class="mb-4 ml-4"
              rounded
              >Back<v-icon color="white">mdi-arrow-left-circle</v-icon></v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Signup Confirmation  -->
    <v-dialog v-model="dialogConfirm" persistent max-width="400px">
      <v-card>
        <v-system-bar color="blue darken-3"> </v-system-bar>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="$emit('closeRegister')">mdi-close-circle-outline</v-icon>
        </v-toolbar>
        <v-form
          @submit.prevent.stop="confirmSignUp()"
          ref="form-confirm"
          autocomplete="off"
        >
          <v-row justify="center" no-gutters>
            <v-card-text align="center" class="mt-7"
              >Please check your email for confirmation code... if not found, pls check your spam folder in case it did not recognize the sender...</v-card-text
            >
            <v-col md="11">
              <v-text-field
                class="ma-3 mt-1 mx-10"
                v-model="code"
                :rules="confirmationRules"
                label="Enter Code"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            v-if="loginError"
            dark
            color="error"
            class="mx-10"
            >{{ loginError }}</v-alert
          >
          <v-row justify="center" no-gutters>
            <v-btn
              dark
              type="submit"
              class="pink accent-3 white--text"
              elevation="10"
              rounded
              >Confirm<v-icon color="white"
                >mdi-ticket-confirmation</v-icon
              ></v-btn
            >
            <v-btn
              class="ml-4 mb-4"
              color="black"
              dark
              @click="resendCode"
              rounded
              >Re-send Code<v-icon color="white"
                >mdi-email-sync-outline</v-icon
              ></v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <CircularProgress v-if="showProgress" />
  </div>
</template>
<script>
import CircularProgress from "@/components/CircularProgress.vue";
// import Register from "@/components/Register.vue"
// import {mapActions} from 'vuex';
export default {
  components: { CircularProgress },
  data: () => ({
    prevRoute: null,
    checked: "N",
    phoneNumber: "",
    dialogConfirm: false,
    dialogRegister: true,
    loginEmail: "",
    loginPassword: "",
    showProgress: false,
    loginError: "",
    code: "",
    phoneNumberRules: [
      (v) => !!v || "Phone Number is required",
      (v) => /^\d{10}$/.test(v) || "10 digit US Phone Number is required (no dashes)",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    confirmationRules: [
      (v) => (v && v.length >= 5) || "Verification code is required",
    ],
  }),
  beforeRouteEnter(to, from, next) {
  next(vm => {
    vm.prevRoute = from
    // console.log(from.path)
  })
},
  methods: {
    async register() {
      this.loginError = ""
      if (!this.$refs["form-register"].validate()) return;
      this.showProgress = true;
      try {

        await this.$store.dispatch("auth/signUp", {
          username: this.loginEmail,
          password: this.loginPassword,
          email: this.loginEmail,
          phone_number: "+1" + this.phoneNumber,
          nickname: this.checked
        });
        this.dialogRegister = false;
        this.dialogConfirm = true;
        this.showProgress = false;
      } catch (err) {
        this.loginError = err.message
        this.showProgress = false;
      }
    },
    async confirmSignUp() {
      this.loginError = ""
      if (!this.$refs["form-confirm"].validate()) return;
      this.showProgress = true;
      try {
        const { loginEmail, loginPassword, code } = this;
        await this.$store.dispatch("auth/confirmSignUp", {
          username: loginEmail,
          code: code,
        });
        await this.$store.dispatch("auth/login", {
          username: loginEmail,
          password: loginPassword,
        });
        this.$emit('closeRegister')
        this.showProgress = false
      } catch (err) {
        this.showProgress = false
        this.loginError = err.message;
      }
    },
    async resendCode() {
      this.loginError = ""
      this.showProgress = true
      try {
        await this.$store.dispatch("auth/resendSignUp", {
          username: this.loginEmail,
        });
        this.loginError = "";
        this.code = "";
        this.showProgress = false;
      } catch (err) {
        this.loginError = err.message;
      }
      this.showProgress = false
    },
  },
};
</script>
