<template>
  <v-overlay v-if="showDialog">
    <v-card color="white" max-width="300" class="mx-auto">
      <v-system-bar color="pink darken-2" dark> </v-system-bar>

      <v-app-bar dark color="pink">
        <v-toolbar-title>Warning!</v-toolbar-title>
      </v-app-bar>

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card color="pink darken-2">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title>90 Mile Radius Selection</v-card-title>

                  <v-card-subtitle
                    >You've selected a huge area to search. If there are a lot
                    of teams within this area, it might take sometime to
                    retrieve, process, and filter data...
                  </v-card-subtitle>
                </div>
              </div>
              <v-btn @click="showDialog = false" class="ml-4 my-5" outlined rounded small> OK </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    showDialog: true,
  }),
};
</script>
