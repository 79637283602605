<template>
  <v-overlay v-if="showDialog">
    <v-card color="white" max-width="350" class="mx-auto">
      <v-system-bar color="pink darken-2" dark> </v-system-bar>

      <v-app-bar dark color="pink">
        <v-toolbar-title>Claim a Team</v-toolbar-title>
      </v-app-bar>

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card color="pink darken-2">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h5">
                    Assign this team to me...
                  </v-card-title>
                  <v-card-subtitle class="mt-0">
                    Team: {{ itemUpd.name }}
                    <br />
                    Coach: {{ itemUpd.coach }}
                    <br />
                    Location: {{ itemUpd.address }} <br
                  /></v-card-subtitle>
                  <v-card-subtitle>
                    I understand that by clicking "Accept" below, I am providing
                    my digital signature that I am taking full responsibility
                    for the accuracy of this team's data.
                    </v-card-subtitle
                  >
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            v-if="errorOnSubmit"
            dark
            color="error"
            class="mx-10 mb-4 mt-4"
            >{{ errorOnSubmit }}
          </v-alert>
        </v-row>
        <v-row class="mb-3 mt-5">
          <v-btn
            @click="declineClaim"
            class="ma-auto"
            rounded
            color="black"
            dark
          >
            DECLINE
          </v-btn>
          <v-btn @click="claimTeam" class="ma-auto" rounded color="black" dark>
            Accept
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
    <CircularProgress v-if="showProgress" />
  </v-overlay>
</template>

<script>
import { API } from "aws-amplify";
import { createMyTeams, createTrackChanges } from "@/graphql/mutations";
import { trackChangesByEmailnAction } from "@/graphql/queries";
import { mapGetters } from "vuex";
import CircularProgress from "@/components/CircularProgress.vue";
export default {
  props: ["itemUpd"],
  components: { CircularProgress },
  data() {
    return {
      numOfAs: 0,
      numOfCs: 0,
      numOfACs: 0,
      year: 0,
      showDialog: true,
      errorOnSubmit: "",
      showProgress: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.calcYear();
    this.getNumOfACs();
  },
  methods: {
    async trackChanges(item){
      // console.log(JSON.parse(item))
      // const itemJSON = JSON.parse(item)

      // remove some attr from item
      delete (item.rangeKey)
      delete (item.phoneNumber)
      delete (item.showPhone)
      try {
        await API.graphql({
          query: createTrackChanges,
          variables: {
            input: {
              ...item,
              action: "C"
            },
          },
        });
      } catch (err) {
        console.log("trackingClaim: ", err);
      }
    },
    async getNumOfACs() {
      var { data } = await API.graphql({
        query: trackChangesByEmailnAction,
        variables: {
          email: this.user.username,
          action: { eq: "A" },
          limits: 1000,
        },
      });
      this.numOfAs = data.trackChangesByEmailnAction.items.length;

      var { data } = await API.graphql({
        query: trackChangesByEmailnAction,
        variables: {
          email: this.user.username,
          action: { eq: "C" },
          limits: 1000,
        },
      });
      this.numOfCs = data.trackChangesByEmailnAction.items.length;

      this.numOfACs = this.numOfAs + this.numOfCs;
    },
    claimTeam() {
      // console.log(JSON.parse(JSON.stringify(this.itemUpd)))

      this.showProgress = true;

      if ((this.numOfACs >= 9)) {
        this.errorOnSubmit = "Max number of team addition and claims reached... if more is needed, pls contact us..."
        this.alertColor = "red"
        this.showProgress = false
        return;

      }

      this.itemUpd.email = this.user.username;

      if(!this.itemUpd.tryout){
        this.itemUpd.tryout = "Tryout Link Here"
      }

      const updateItem = {
        tblName: this.itemUpd.tblName,
        rangeKey: this.itemUpd.rangeKey,
        lat: this.itemUpd.coords.lat,
        lng: this.itemUpd.coords.lng,
        address: this.itemUpd.address,
        name: this.itemUpd.name,
        class: this.itemUpd.class,
        coach: this.itemUpd.coach,
        teamID: (this.itemUpd.teamID).toString(),
        email: this.user.username,
        pickup: this.itemUpd.pickup,
        pickupDate: this.itemUpd.pickupDate,
        tryout: this.itemUpd.tryout,
        tryoutDate: this.itemUpd.tryoutDate,
        teamURL: this.itemUpd.teamURL,
        year: this.year,
        zip: this.itemUpd.zip || "",
        byear: (this.itemUpd.byear || "0").toString(),
        phoneNumber: this.itemUpd.phoneNumber || "",
        showPhone: this.itemUpd.showPhone || "",
      };
      const dataJSON = JSON.parse(JSON.stringify(updateItem));
      
      this.updateData(dataJSON);
      this.trackChanges(dataJSON)
      this.numOfACs++
    },
    async updateData(item) {
      this.errorOnSubmit = "";
      const url =
        "https://e3hjsr56sqo4wn7b33b6sfmiju0uubjy.lambda-url.us-east-1.on.aws/"; // s4t-search-teams-claim-item2
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(item);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        await fetch(url, requestOptions)
          .then((response) => this.getStatus(response.status))
          .then(this.trackInvetoryInMyTeams());
      } catch (err) {
        this.errorOnSubmit = err.message;
      }
    },
    async trackInvetoryInMyTeams() {
      try {
        await API.graphql({
          query: createMyTeams,
          variables: {
            input: {
              name: this.itemUpd.name,
              class: this.itemUpd.class,
              email: this.user.username,
              tblName: this.itemUpd.tblName,
              year: this.year,
            },
          },
        });
      } catch (error) {
        this.errorOnSubmit = error;
      }
    },
    getStatus(status) {
      this.errorOnSubmit = "";
      if (status === 200) {
        this.$emit("OK");
      } else {
        this.alertColor = "error";
        this.errorOnSubmit =
          "Sorry... pls close your other apps and try again... or contact us if it persists...";
      }
    },
    calcYear() {
      const mon = new Date().getMonth() + 1;
      const day = new Date().getDate().toString().padStart(2, "0");
      const yr = new Date().getFullYear().toString().substr(-2);
      this.year = mon + day + yr;
    },
    closeClaim() {
      // this.showDialog = false;
      this.$emit("OK");
    },
    declineClaim() {
      this.$emit("Decline");
    },
  },
};
</script>
