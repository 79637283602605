<template>
  <div>
    <v-dialog v-model="dialogLogin" persistent max-width="400px">
      <v-card>
        <!-- <v-system-bar color="blue darken-3"> </v-system-bar> -->
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Login</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="$emit('ccloseLogin')">mdi-close-circle-outline</v-icon>
        </v-toolbar>
        <v-form
          @submit.prevent.stop="login()"
          ref="form-login"
          autocomplete="off"
        >
          <v-row class="mt-6" justify="center" align="center" no-gutters>
            <v-col md="11">
              <v-text-field
                class="mx-10"
                v-model="loginEmail"
                :rules="emailRules"
                label="Email"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-col md="11">
              <v-text-field
                class="mx-10"
                v-model="loginPassword"
                :rules="passwordRules"
                type="password"
                label="Password"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            v-if="loginError"
            dark
            color="error"
            class="mx-10"
            >{{ loginError }}</v-alert
          >
          <v-row class="mt-7" justify="center" no-gutters>
            <v-btn
              dark
              type="submit"
              class="pink accent-3 white--text mb-4"
              elevation="10"
              rounded
              >Login<v-icon color="white">mdi-login</v-icon></v-btn
            >
            <v-btn
              @click="$emit('registerClaim')"
              color="black"
              dark
              class="mb-4 ml-4"
              rounded
              >Register<v-icon color="white">mdi-account-plus</v-icon></v-btn
            >
            <v-btn
              @click="resetPassword"
              color="black"
              dark
              class="mb-4"
              rounded
              >Reset Password<v-icon color="white"
                >mdi-lock-reset</v-icon
              ></v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Forgot Password  -->
    <v-dialog v-model="dialogForgot" persisten max-width="400px">
      <v-card>
        <v-system-bar color="blue darken-3"> </v-system-bar>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Forgot Password</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="closeForgot">mdi-close-circle-outline</v-icon>
        </v-toolbar>
        <v-form
          @submit.prevent.stop="forgotSend()"
          ref="form-forgot"
          autocomplete="off"
        >
          <v-row justify="center" no-gutters>
            <v-col md="11">
              <v-text-field
                dense
                class="ma-3 mt-10 mx-10"
                v-model="loginEmail"
                label="Enter Email"
                :rules="emailRules"
                outlined
              ></v-text-field>

              <v-alert
                outlined
                type="warning"
                prominent
                border="left"
                v-if="loginError"
                dark
                color="error"
                class="mx-10"
                >{{ loginError }}</v-alert
              >
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-btn
              dark
              rounded
              type="submit"
              class="pink accent-3 white--text mb-4"
              elevation="10"
              >Reset Password<v-icon color="white"
                >mdi-ticket-confirmation</v-icon
              ></v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Forgot Password Submit -->
    <v-dialog v-model="dialogForgotSubmit" persistent max-width="400px">
      <v-card>
        <v-system-bar color="blue darken-3"> </v-system-bar>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Change Password</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="closeForgot">mdi-close-circle-outline</v-icon>
        </v-toolbar>
        <v-form
          @submit.prevent.stop="forgotSubmit()"
          ref="form-forgotSubmit"
          autocomplete="off"
        >
          <v-row justify="center" no-gutters>
            <v-card-text align="center"
              >Please check your email for confirmation code</v-card-text
            >
            <v-col md="11">
              <v-text-field
                dense
                class="mx-10"
                v-model="code"
                :rules="confirmationRules"
                label="Confirmation Code"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-col md="11">
              <v-text-field
                dense
                :rules="passwordRules"
                class="mx-10"
                v-model="loginPassword"
                type="password"
                label="Enter New Password"
                outlined
              ></v-text-field>
              <v-alert
                outlined
                type="warning"
                prominent
                border="left"
                v-if="loginError"
                dark
                color="error"
                class="mx-10"
                >{{ loginError }}</v-alert
              >
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-btn 
            dark
              rounded
              type="submit"
              class="pink accent-3 white--text mb-4"
              elevation="10"
              >Submit<v-icon color="white">mdi-file-send-outline</v-icon></v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <CircularProgress v-if="showProgress" />
  </div>
</template>
<script>
import CircularProgress from "@/components/CircularProgress.vue";
// import Register from "@/components/Register.vue"
import { mapActions } from "vuex";
export default {
  components: { CircularProgress },
  data: () => ({
    code: "",
    dialogForgotSubmit: false,
    dialogForgot: false,
    dialogLogin: true,
    loginEmail: "",
    loginPassword: "",
    showProgress: false,
    loginError: "",

    // confirmEmail: "",
    // confirmConfirmation: "",
    // signupEmail: "",
    // signupPassword: "",
    // signupPasswordConfirm: "",
    // signupError: "",
    // loading: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    confirmationRules: [
      (v) =>
        (v && v.length >= 5) || "Verification code must be at least 5 numbers",
    ],
  }),
  // created(){
  //   if(this.$store.state.user){
  //     this.dialogLogin = false
  //   }
  // },
  methods: {
    ...mapActions({
      loginVue: "auth/login",
    }),
    async forgotSubmit(){
      console.log("forgotSubmit")
      this.loginError = ""
      if (!this.$refs["form-forgotSubmit"].validate()) return;
      this.showProgress = true;

      try {
        await this.$store.dispatch("auth/forgotSubmit", {
          username: this.loginEmail,
          code: this.code,
          password: this.loginPassword
        });
        
        await this.$store.dispatch("auth/login", {
          username: this.loginEmail,
          password: this.loginPassword,
        });        
        
        this.dialogForgotSubmit = false;
      } catch (err) {
        this.loginError = err.message;
      }
      this.showProgress = false;
    },
    async forgotSend() {
      // asking for email address
      
      if (!this.$refs["form-forgot"].validate()) return;
      this.showProgress = true;
      this.dialogForgot = false;
      try {
        await this.$store.dispatch("auth/forgotPwdEmail", {
          username: this.loginEmail,
        });

        this.dialogForgotSubmit = true;
        
        this.loginPassword = ""
      } catch (err) {
        this.loginError = err.message;
      }
      this.showProgress = false;
    },
    closeForgot() {
      this.loginError = ""
      this.dialogLogin = true;
      this.dialogForgot = false;
      this.dialogForgotSubmit = false
    },
    resetPassword() {
      this.dialogLogin = false;
      this.dialogForgot = true;
    },
    async login() {
      this.loginError = ""
      if (!this.$refs["form-login"].validate()) return;
      this.showProgress = true;
      try {
        await this.loginVue({
          username: this.loginEmail,
          password: this.loginPassword,
        });
        this.dialogLogin = false;
        this.$emit('closeLogin')
      } catch (err) {
        this.loginError = err.message;
      }
      this.showProgress = false;
    },
    // async userLogin() {
    //   if (!this.$refs["form-login"].validate()) return;
    //   this.loading = true;
    //   await this.$store.dispatch("login", {
    //     email: this.loginEmail,
    //     password: this.loginPassword,
    //   });
    //   if(this.$store.state.user)
    //   // console.log(JSON.parse(JSON.stringify(this.$store.state.user)))
    //   this.loading = false;
    //   return false;
    // },
  },
};
</script>
