<template>
  <div>
    <v-overlay v-if="showProgress">
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular
            indeterminate
            size="60"
            width="5"
          ></v-progress-circular>
          <span class="ml-2">{{ loadingWords }}</span>
        </v-card-text>
      </v-card>
    </v-overlay>
    <ModalConfirm
      v-if="showDialog"
      :itemFilteredTeams="itemFilteredTeams"
      @OK="showDialog = false"
    />
    <ModalClaimNotice
      v-if="showClaim"
      :itemUpd="itemFilteredTeams"
      @OK="postClaim"
      @Decline="showClaim = false"
    />
    <ModalFeedback
      v-if="showFeedback"
      :itemFilteredTeams="itemFilteredTeams"
      :sport="selectedSport"
      :state="selectedState"
      :showFeedback="showFeedback"
      @OK="showFeedback = false"
    />
    <ModalDistanceWarning v-if="showDistanceWarning" />
    <ModalInaccurateData v-if="showInaccurateData" />
    <ModalStateWarning v-if="showStateWarning" @OK="showStateWarning = false" />
    <v-form
      @submit.prevent.stop="findGeo()"
      ref="form-search"
      autocomplete="off"
    >
      <v-row justify="center" class="mt-4 mx-auto" no-gutters>
        <v-col lg="3">
          <v-select
            dense
            class="ml-5 mt-4 mr-2"
            :items="sports"
            item-text="text"
            item-disabled="inactive"
            v-model="selectedSport"
            :rules="[rules.required]"
            label="Sport"
            @change="resetSearch"
            filled
            outlined
          ></v-select>
        </v-col>
        <v-col lg="3">
          <v-select
            dense
            class="ml-2 mt-4 mr-5"
            v-model="selectedDistance"
            @change="resetSearch"
            :items="distances"
            label="Distance (mi)"
            hint="Distance from your location"
            filled
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center" style="margin-top: -20px" no-gutters>
        <v-col lg="3">
          <v-select
            dense
            class="ml-5 mt-4 mr-2"
            v-model="selectedAgeGroup"
            :rules="[rules.required]"
            @change="resetSearch"
            :items="ageGroup"
            label="Age Group"
            :disabled="!selectedSport"
            filled
            outlined
          ></v-select>
        </v-col>
        <v-col lg="3">
          <!-- <v-select
            dense
            class="ml-2 mt-4 mr-5"
            v-model="selectedClassLevel"
            @change="resetSearch"
            :items="classLevel"
            :disabled="!selectedSport"
            label="Class"
            filled
            outlined
            :rules="[rules.required]"
          ></v-select> -->
          <v-select
            dense
            class="ml-2 mt-4 mr-5"
            v-model="selectedClassLevel"
            @change="resetSearch"
            :items="classLevel"
            :disabled="!selectedSport"
            label="Class"
            filled
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center" style="margin-top: -20px" no-gutters>
        <v-col cols="6" md="3">
          <v-select
            dense
            class="ml-5 mt-4 mr-2"
            :items="states"
            item-text="text"
            item-disabled="inactive"
            v-model="selectedState"
            :rules="[rules.required]"
            label="State"
            :disabled="!selectedSport"
            @change="resetSearch"
            filled
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" md="3" v-if="showZip">
          <v-text-field
            v-model="selectedZipCode"
            :rules="zipRequired ? [rules.required] : []"
            :required="!zipRequired"
            hint="Defines your geolocation..."
            persistent-hint
            class="ml-2 mr-5 mt-4"
            dense
            label="Zip Code"
            filled
            outlined
            @click="resetSearch"
            :disabled="!selectedSport"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-row justify="center">
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            v-if="errorOnSubmit"
            dark
            :color="alertColor"
            class="mx-10 mb-10"
            >{{ errorOnSubmit }}
          </v-alert>
        </v-row>
      </v-row>
      <v-row justify="center" no-gutters class="mb-7">
        <v-col cols="3" md="1" class="d-flex justify-center">
          <v-btn
            type="submit"
            class="pink accent-3 white--text"
            elevation="10"
            rounded
          >
            Search
          </v-btn>
        </v-col>
        <v-col
          cols="3"
          md="1"
          class="d-flex justify-center"
          v-if="softballwi.length > 0"
        >
          <v-checkbox
            class="ml-4"
            style="margin-top: -1px"
            v-model="tryoutCheckbox"
            @change="queryTeamsByAll"
            label="Tryout"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="3"
          md="1"
          class="d-flex justify-center"
          v-if="softballwi.length > 0"
        >
          <v-checkbox
            style="margin-top: -1px"
            v-model="pickupCheckbox"
            @change="queryTeamsByAll"
            label="Pickup"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row
        v-if="!searchStarted"
        class="align-center justify-center"
        style="height: 170px"
      >
        <v-col class="d-flex justify-center mb-5">
          <v-img src="mainS4T.jpeg" max-height="300" max-width="300"></v-img>
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table class="my-2" v-if="searchStarted">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left blue darken-3 white--text"><h3>Team</h3></th>
            <th class="text-left blue darken-3 white--text">
              <h3>Location (Distance)</h3>
            </th>
            <th class="text-left blue darken-3 white--text">
              <h3>More Info</h3>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredTeams" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.address }} ({{ item.distance }} mi)</td>
            <td>
              <v-row>
                <v-col>
                  <v-menu transition="scale-transition" origin="center center">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark color="primary" v-bind="attrs" v-on="on">
                        Info
                      </v-btn>
                    </template>

                    <v-list shaped dark color="primary">
                      <v-list-item-group v-model="selectedInfo">
                        <v-list-item
                          link
                          v-for="n in item.moreInfoOptions"
                          :key="n.id"
                          @change="moreInfo(item, n.text)"
                        >
                          <v-list-item-icon>
                            <v-icon v-text="n.icon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="n.text"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-system-bar color="blue darken-3"></v-system-bar>
    <v-row justify="center">
      <v-col lg="3" md="2">
        <v-img
          class="mx-auto"
          src="InPartnership.jpeg"
          max-height="200"
          max-width="200"
        ></v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="3" md="2">
        <a href="https://www.dbatallen.com/" target="_blank">
          <v-img
            class="mx-auto mb-16"
            src="DbatAllen.jpeg"
            max-height="150"
            max-width="100"
          ></v-img>
      </a>
      </v-col>
      <v-col lg="3" md="2">
        <a href="https://www.dbatmelissa.com/" target="_blank">
          <v-img
            class="mx-auto mb-16"
            src="DbatMelissa.jpeg"
            max-height="150"
            max-width="100"
          ></v-img>
        </a>
      </v-col>
    </v-row>
    <v-card max-width="400" class="mx-auto mt-16">
      <v-system-bar color="blue darken-3" dark> </v-system-bar>
      <v-app-bar dark color="blue darken-2">
        <v-toolbar-title class="mx-auto">Our Mission</v-toolbar-title>
      </v-app-bar>
      <v-container class="mb-8">
        <v-row dense>
          <v-col cols="12">
            <v-card class="blue darken-3" dark>
              <v-card-subtitle>
                Find a team near you with a couple of clicks... Simple and Easy!
                Select your target/interest via dropdown options and then filter
                for tryout or pickup... Voila!!!
              </v-card-subtitle>
              <v-card-subtitle>
                Our goal is to help make it easier for parents to search for
                teams in their area by providing who, what, where, and when are
                these teams having tryouts or if they are looking to pickup. By
                making it easy to look for teams, we hope that we are helping
                promote community sports that will have a lasting positive
                impact all around.
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <ModalZeroSearch v-if="dialogZeroSearch" @OK="dialogZeroSearch=false" />
  </div>
</template>

<script>
import ModalZeroSearch from "@/components/ModalZeroSearch.vue";
import ModalConfirm from "./ModalConfirm.vue";
import ModalFeedback from "./ModalFeedback.vue";
import ModalDistanceWarning from "./ModalDistanceWarning.vue";
import ModalInaccurateData from "./ModalInaccurateData.vue";
import ModalStateWarning from "./ModalStateWarning.vue";
import ModalClaimNotice from "./ModalClaimNotice.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  components: {
    ModalConfirm,
    ModalFeedback,
    ModalDistanceWarning,
    ModalInaccurateData,
    ModalStateWarning,
    ModalClaimNotice,
    ModalZeroSearch
  },
  data() {
    return {
      dialogZeroSearch: false,
      zipRequired: false,
      showClaim: false,
      errorOnSubmit: "",
      alertColor: "error",
      sportAbbr: "",
      ageGroup: [],
      selectedAgeGroup: "",
      classLevel: [],
      selectedClassLevel: "",
      selectedZipCode: "",
      showZip: true,
      searchType: ["Use Zip Code", "My Location"],
      selectedSearchTypes: "Use Zip Code",
      showDistanceWarning: false,
      showInaccurateData: false,
      showStateWarning: false,
      resetTryoutPickup: false,
      loadingWords: "",
      showProgress: false,
      location: "",
      tryoutCheckbox: false,
      pickupCheckbox: false,
      sports: [
        { text: "Baseball", inactive: false },
        { text: "Fastpitch", inactive: false }
      ],
      states: [],
      selectedState: "",
      selectedSport: "",
      selectedClass: "",
      distances: [15, 30, 60, 90],
      distanceMi2M: [
        { mi: 15, m: 25000 },
        { mi: 30, m: 49000 },
        { mi: 60, m: 97000 },
        { mi: 90, m: 145000 },
      ],
      selectedDistance: 15,
      filteredTeams: [],
      uniqueClass: [],
      softballwi: [],
      selectedInfo: "",
      showDialog: false,
      showFeedback: false,
      itemFilteredTeams: [],
      today: new Date(),
      drawer: false,
      searchStarted: false,
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      bbStates: "auth/bbStates",
      bbAgeGroup: "auth/bbAgeGroup",
      bbClassLevel: "auth/bbClassLevel",
      fpStates: "auth/fpStates",
      fpAgeGroup: "auth/fpAgeGroup",
      fpClassLevel: "auth/fpClassLevel",
      os: "auth/os",
    }),
  },
  created() {
    this.restoreFromLocal();
    this.getOS();
  },
  methods: {
    ...mapActions({
      findOSvue: "auth/findOS",
    }),
    getOS() {
      this.findOSvue();
    },
    postClaim() {
      this.showClaim = false;

      // find Index number
      const itemIndex = this.filteredTeams
        .map((e) => e.rangeKey)
        .indexOf(this.itemFilteredTeams.rangeKey);

      const post1 = this.filteredTeams[itemIndex].moreInfoOptions;
      const post2 = post1.filter(function (item) {
        return item.text !== "Claim";
      });
      this.filteredTeams[itemIndex].moreInfoOptions = post2;

      // console.log(this.filteredTeams.indexOf(this.itemFilteredTeams))
      // console.log(JSON.stringify(this.itemFilteredTeams))
      this.save2Local();
    },
    save2Local() {
      const params = {
        sport: this.selectedSport,
        distance: this.selectedDistance,
        ageGroup: this.selectedAgeGroup,
        classLevel: this.selectedClassLevel,
        state: this.selectedState,
        zipCode: this.selectedZipCode,
      };
      localStorage.setItem("paramsUsedEDH", JSON.stringify(params));
      localStorage.setItem(
        "filteredTeamsEDH",
        JSON.stringify(this.filteredTeams)
      );
    },
    restoreFromLocal() {
      const paramsUsed = JSON.parse(localStorage.getItem("paramsUsedEDH"));
      if (paramsUsed) {
        this.selectedSport = paramsUsed.sport;
        this.selectedDistance = paramsUsed.distance;
        this.selectedAgeGroup = paramsUsed.ageGroup;
        this.selectedClassLevel = paramsUsed.classLevel;
        this.selectedState = paramsUsed.state;
        this.selectedZipCode = paramsUsed.zipCode;

        this.filteredTeams = JSON.parse(
          localStorage.getItem("filteredTeamsEDH")
        );

        this.searchStarted = true;
      }
    },
    displayZip() {
      this.resetSearch();
      if (this.selectedSearchTypes === "Use Zip Code") {
        this.showZip = true;
      } else {
        this.showZip = false;
      }
    },
    claimOption(email) {
      if ((!email || email.substring(0,4) === "UZU-") && this.user) {
        return { id: 6, icon: "mdi-pin", text: "Claim" };
      } else {
        return null;
      }
    },
    emailOption(email) {
      if (email) {
        return { id: 5, icon: "mdi-email-arrow-right-outline", text: "Email" };
      } else {
        return {
          id: 5,
          icon: "mdi-minus-circle-off-outline",
          text: "No Contact",
        };
      }
    },
    msgOption(email, phoneNumber, showPhone) {
      // const trimPhone = phoneNumber
      //   .substring(2)
      //   .replace(
      //     /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
      //     "$1$2$3-$4$5$6-$7$8$9$10"
      //   );
      // if (this.os === "Windows" && showPhone === "Y") {
      //   return {
      //     id: 4,
      //     icon: "mdi-message-arrow-right-outline",
      //     text: trimPhone,
      //   };
      // } else
      if(phoneNumber.substring(0,4) === "UZU-" || (email && phoneNumber && showPhone === "Y")){
      // }
      // if (email && phoneNumber && showPhone === "Y") {
        return {
          id: 4,
          icon: "mdi-message-arrow-right-outline",
          text: "Message",
        };
      } else {
        return null;
      }
    },
    tryoutOption(tryout, tryoutDate) {
      const formattedDate = new Date(tryoutDate);
      formattedDate.setDate(formattedDate.getDate() + 1);

      const formatDate = new Date(formattedDate).toLocaleDateString("en-us", {
        month: "numeric",
        day: "numeric",
      });

      // if there's no tryout link or expired tryout date, show "No Tryout"
      if (!tryout || formattedDate.getTime() < this.today.getTime()) {
        // return null;
        return {
          id: 3,
          icon: "mdi-minus-circle-off-outline",
          text: "No Tryout",
        };        
      } else if(tryout === "Tryout Link Here") {
          return {
            id: 3,
            icon: "mdi-account-convert-outline",
            text: "Open Practice",
          }
        // if there's a tryout link but no tryout date, then show "Tryout - Private"
      } else if (tryout && !tryoutDate) {
        return {
          id: 3,
          icon: "mdi-account-convert-outline",
          text: "Tryout - Private",
        };
      } else {
        return {
          id: 3,
          icon: "mdi-account-convert-outline",
          text: "Tryout - " + formatDate,
        };
      }
    },
    pickupOption(pickup, pickupDate) {
      const formattedDate = new Date(pickupDate);
      formattedDate.setDate(formattedDate.getDate() + 1);
      const formatDate = new Date(formattedDate).toLocaleDateString("en-us", {
        month: "numeric",
        day: "numeric",
      });

      // if there's no pickup link or expired pickup date, show "No pickup"
      if (!pickup || formattedDate.getTime() < this.today.getTime()) {
        // return null;
        return {
          id: 2,
          icon: "mdi-information-off-outline",
          text: "No Pickup",
        };

        // if there's a tryout link but no tryout date, then show "Tryout - Private"
      } else if (pickup && !pickupDate) {
        return {
          id: 2,
          icon: "mdi-account-arrow-up-outline",
          text: "Pickup - Contact Coach",
        };
      } else {
        return {
          id: 2,
          icon: "mdi-account-arrow-up-outline",
          text: "Pickup - " + formatDate,
        };
      }
    },
    detailOption(id, url) {
      // if (url || id > 1) {
      // if any of the first AND second condition is true, then No Detail
      if (
        (url === "0" || !url || url === "") &&
        (id === "0" || !id || id === "")
      ) {
        return {
          id: 1,
          icon: "mdi-information-off-outline",
          text: "No Detail",
        };
      } else {
        return { id: 1, icon: "mdi-information-outline", text: "Team Details" };
      }
    },
    moreInfo(item, selectedInfo) {
      // Don't Do Anything even after selecting an option
      if (
        selectedInfo === "No Tryout" ||
        selectedInfo === "No Pickup" ||
        selectedInfo === "No Detail" ||
        selectedInfo === "No Contact" 
      ) {
        return;
      } else if (selectedInfo === "Report") {
        this.showFeedback = true;
        this.itemFilteredTeams = {
          ...item,
          selectedInfo: "Report",
        };
      } else if (selectedInfo === "Claim") {
        if (!this.user) {
          this.$router.push("/login");
        }
        this.showClaim = true;
        this.itemFilteredTeams = {
          ...item,
          selectedInfo: "Claim",
          phoneNumber: this.user.attributes.phone_number,
          showPhone: this.user.attributes.nickname,
        };
      } else if (selectedInfo.substring(0, 6) === "Tryout") {
        this.showDialog = true;
        this.itemFilteredTeams = {
          ...item,
          selectedInfo: "Tryout",
        };
      } else if (selectedInfo.substring(0, 6) === "Pickup") {
        this.showDialog = true;
        this.itemFilteredTeams = {
          ...item,
          selectedInfo: "Pickup",
        };
      } else if (selectedInfo.substring(0, 6) === "Email") {
        this.sendEmail(item.email, item.name);
      } else if (selectedInfo.substring(0, 6) === "Messag") {
        if (this.os === "Windows") {
          this.showDialog = true
        } else {
          // const trimPhone = item.phoneNumber.substring(2);
          
          let trimPhone
          if(item.phoneNumber.substring(0,4) === "UZU-"){
            trimPhone = item.phoneNumber.substring(6)
          } else {
            trimPhone = item.phoneNumber.substring(2)
          }
          this.sendMsg(trimPhone, item.name);
        }
      } else {
        this.showDialog = true;
        this.itemFilteredTeams = {
          ...item,
          selectedInfo: selectedInfo,
        };
      }
    },
    findGeo() {
      this.errorOnSubmit = "";
      this.$refs["form-search"].validate();
      if (this.os !== "iOS") {
        this.zipRequired = true;
        if (
          !this.selectedSport ||
          !this.selectedAgeGroup ||
          // !this.selectedClassLevel ||
          !this.selectedState ||
          !this.selectedZipCode
        ) {
          this.alertColor = "error";
          this.errorOnSubmit = "Please provide missing info...";
          return;
          // if (!this.$refs["form-search"].validate()) { return; }
        }
      } else if (
        !this.selectedSport ||
        !this.selectedAgeGroup ||
        // !this.selectedClassLevel ||
        !this.selectedState
      ) {
        this.alertColor = "error";
        this.errorOnSubmit = "Please provide missing info...";
        if (!this.$refs["form-search"].validate()) return;
      }

      if (!this.selectedZipCode) {
        if (!("geolocation" in navigator)) {
          this.errorOnSubmit =
            "Sorry, your browser or app is blocking your geolocation feature... Please search using zip code instead... Thank you!";
          return;
        }
        // get position
        this.errorOnSubmit = "Getting your location...";
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.errorOnSubmit = "";
            this.location = pos;

            this.selectedZipCode = "0";

            this.findNearMe(
              this.location.coords.latitude,
              this.location.coords.longitude
            );
          },
          (err) => {
            this.errorOnSubmit = err.message;
          }
        );
      } else {
        this.findNearMe(0, 0);
      }
    },
    async processNewData(newsoftball, lat, lng, tblName, status) {
      if (newsoftball === "States Do Not Match") {
        this.showStateWarning = true;
        this.showProgress = false;
        return;
      } else if (status === 500) {
        this.errorOnSubmit =
          "Sorry... pls use another zip code in the same area...";
        this.alertColor = "red";
        this.showProgress = false;
      } else {
        const getHaversineDistance = require("get-haversine-distance");
        const p1 = {
          lat: lat,
          lng: lng,
        };

        this.softballwi = [];
        JSON.parse(newsoftball).forEach((item) => {
          this.softballwi.push({
            ...item,
            tblName: tblName,
            map:
              "https://www.google.com/maps/dir/" +
              lat +
              "," +
              lng +
              "/" +
              item.coords.lat +
              ", " +
              item.coords.lng,
            distance: Math.round(
              getHaversineDistance(
                p1,
                {
                  lat: item.coords.lat,
                  lng: item.coords.lng,
                },
                { inMiles: true }
              )
            ),
            moreInfoOptions: [
              this.detailOption(item.teamID, item.teamURL),
              // this.directionOption(this.selectedZipCode),
              this.tryoutOption(item.tryout, item.tryoutDate),
              this.pickupOption(item.pickup, item.pickupDate),
              this.emailOption(item.email),
              this.msgOption(item.email, item.phoneNumber, item.showPhone),
              this.claimOption(item.email),
              { id: 7, icon: "mdi-message-alert-outline", text: "Report" },
            ].filter((n) => n),
            tryoutDateFormatted: new Date(item.tryoutDate),
            pickupDateFormatted: new Date(item.pickupDate),
          });
        });
        if(this.softballwi.length === 0){
          this.dialogZeroSearch = true
          this.showProgress = false
          return;
        } else {
          this.displayData();
          this.showInaccurateData = true
        }
      }
    },
    sendEmail(email, name) {
      if(email.substring(0,4) === "UZU-"){
        email=email.substring(4)
      }
      var subject = "We are interested in " + name;
      var emailBody =
        "I found your team in Search4Teams.com... If you're still looking for a player, please let me know. Thank you.";
      document.location =
        "mailto:" + email + "?cc=info@search4teams.com&subject=" + subject + "&body=" + emailBody;
    },
    sendMsg(trimPhone, name) {
      if(trimPhone.substring(0,4) === "UZU-"){
        trimPhone = trimPhone.substring(4)
      }
      if (this.os === "Android") {
        // window.open("sms:"+ trimPhone + "?&body="+" \n "+ localStorage.getItem('firstName') + " " + "(AppName) : "+ smsText);
        window.location.href =
          "sms:" +
          // "sms:/open?addresses=" +
          trimPhone +
          ",4695250450?&body=" +
          "Coach, I found " +
          name +
          " in Search4Teams.com... If you're still looking for a player, please let me know. Thank you.";
      } else {
        window.location.href =
        // "sms:/open?addresses=9043927442,4695250450&body=" +
          // "sms:" +
          "sms:/open?addresses=" +
          trimPhone +
          ",4695250450&body=" +
          encodeURIComponent(
            "Coach, I found " +
              name +
              " in Search4Teams.com... If you're still looking for a player, please let me know. Thank you."
          );
      }
    },
    directionOption(zip) {
      if (!zip) {
        return { icon: "mdi-google-maps", text: "Directions" };
      } else {
        return { icon: "mdi-google-maps", text: "No Directions" };
      }
    },
    displayData() {
      this.filteredTeams = this.softballwi;
      this.filteredTeams.sort((a, b) => a.distance - b.distance);
      this.searchStarted = true;
      this.tryoutCheckbox = false;
      this.pickupCheckbox = false;
      this.showProgress = false;
      this.resetTryoutPickup = true;
      this.save2Local();
    },
    displayProgress() {
      this.loadingWords = "";
      this.showProgress = true;

      var self = this;
      setTimeout(function () {
        self.loadingWords = "Searching for teams...";
      }, 10);
      var self = this;
      setTimeout(function () {
        self.loadingWords = "Retrieving data...";
      }, 3000);
      setTimeout(function () {
        self.loadingWords = "Sorting by geo-distance...";
      }, 6000);
      setTimeout(function () {
        self.loadingWords = "Geo-Distance is a straight line...";
      }, 9000);
      setTimeout(function () {
        self.loadingWords = "Pls confirm data w coaches...";
      }, 12000);
      setTimeout(function () {
        self.loadingWords = "Finishing up...";
      }, 15000);
      setTimeout(function () {
        self.loadingWords = "Lots of data...";
      }, 18000);
      setTimeout(function () {
        self.loadingWords = "Good Luck!";
      }, 21000);
    },
    resetSearch() {
      if (this.selectedDistance === 90) {
        this.showDistanceWarning = true;
      }
      this.softballwi = [];
      this.filteredTeams = [];
      this.errorOnSubmit = "";
      this.resetTryoutPickup = false;
    },
    async findNearMe(lat, lng) {
      // convert selected distance in miles to meters
      const inMeters = this.distanceMi2M.filter(
        (distance) => distance.mi === this.selectedDistance
      );
      const url =
        "https://elzqkkcp67zwyutjfkdosuei5e0ceehl.lambda-url.us-east-1.on.aws/"; // s4t_search_teams_lambdaC6
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      // Determine DDB tablename
      if (this.selectedSport === "Baseball") {
        this.sportAbbr = "bb";
      } else if (this.selectedSport === "Fastpitch") {
        this.sportAbbr = "fp";
      }
      // console.log(this.sportAbbr + (this.selectedState).toLowerCase() + "23")

      var raw = JSON.stringify({
        latitude: lat,
        longitude: lng,
        radius: inMeters[0].m,
        category: this.selectedSport,
        state: this.selectedState,
        tblName: this.sportAbbr + this.selectedState.toLowerCase() + "23",
        level: (this.selectedAgeGroup + " " + this.selectedClassLevel).trim(),
        zip: this.selectedZipCode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        fetch(url, requestOptions)
          .then(this.displayProgress())
          .then((response) => response.json())
          .then((result) =>
            this.processNewData(
              result[0],
              result[1],
              result[2],
              result[3],
              result[4]
            )
          );
      } catch (err) {
        console.log(err);
        this.showProgress = false;
      }

      if (this.selectedZipCode === "0") {
        this.selectedZipCode = null;
      }
    },
    queryTeamsByAll() {
      if (!this.selectedDistance) {
        this.selectedDistance = 500;
      }

      if (this.tryoutCheckbox && !this.pickupCheckbox) {
        this.filteredTeams = this.softballwi.filter(
          (item) =>
            item.tryoutDateFormatted.getTime() > this.today.getTime() ||
            (item.tryout && !item.tryoutDate)
        );
      } else if (!this.tryoutCheckbox && this.pickupCheckbox) {
        this.filteredTeams = this.softballwi.filter(
          (item) =>
            item.pickupDateFormatted.getTime() > this.today.getTime() ||
            (item.pickup && !item.pickupDate)
        );
      } else if (this.tryoutCheckbox && this.pickupCheckbox) {
        this.filteredTeams = this.softballwi.filter(
          (item) =>
            (item.tryoutDateFormatted.getTime() > this.today.getTime() ||
              (item.tryout && !item.tryoutDate)) &&
            (item.pickupDateFormatted.getTime() > this.today.getTime() ||
              (item.pickup && !item.pickupDate))
        );
      } else {
        this.filteredTeams = this.softballwi;
      }
      this.filteredTeams.sort((a, b) => a.distance - b.distance);
      this.save2Local();
    },
    dateFormatter(date) {
      const formattedDate = new Date(date);
      return formattedDate;
    },
  },
  watch: {
    selectedSport: function () {
      if (this.selectedSport === "Baseball") {
        this.states = this.bbStates;
        this.ageGroup = this.bbAgeGroup;
        this.classLevel = this.bbClassLevel;
      } else if (this.selectedSport === "Fastpitch") {
        this.states = this.fpStates;
        this.ageGroup = this.fpAgeGroup;
        this.classLevel = this.fpClassLevel;
      }
    },
  },
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
