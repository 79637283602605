<template>
  <v-overlay v-if="showDialog">
    <v-card color="white" max-width="350" class="mx-auto">

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card class="blue darken-3" dark>
              <div class="d-flex flex-no-wrap justify-space-between mt-3">
                <div>
                  <v-card-subtitle>
                    Please select a different "Class"
                    or increase the "Distance"... then SEARCH again...
                  </v-card-subtitle>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1 mt-5">
          <v-btn @click="closeDialog" class="ma-auto" rounded color="black" dark>
            OK
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      showDialog: true,
      errorOnSubmit: "",
      showProgress: false,
    };
  },
  methods: {
    closeDialog(){
      this.showDialog = false
      this.$emit("OK")
    }
  },
};
</script>
