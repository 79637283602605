<template>
  <v-row justify="center">
    <v-dialog v-model="showFeedback" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <!-- <span class="text-h5">Feedback for "{{ itemFilteredTeams.name }}"</span> -->
          <h5 class="mr-2">Report a Data Problem for:</h5>
          <h5 class="primary--text">{{ itemFilteredTeams.name }}</h5>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-textarea
              v-model="message"
              auto-grow
              filled
              color="deep-purple"
              label="Anything inaccurate with our data? Anything to add or delete?"
              rows="4"
            ></v-textarea>
            <v-row no-gutters>
              <v-col cols="10">
                <v-text-field
                  v-model="name"
                  label="Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email *"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-5 mx-auto">
              <v-radio-group v-model="type" row justify="center">
                <v-radio value="Parent" label="Parent"></v-radio>
                <v-radio value="Coach" label="Coach"></v-radio>
              </v-radio-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="mb-5" justify="center" no-gutters>
          <span
            v-if="errorOnSubmit"
            align="center"
            class="font-weight-bold red--text"
            >Please provide email... so, we can get details if needed...</span
          >
          <p align="center" class="font-weight-bold red--text">
            {{ emailError }}
          </p>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="blue white--text" text @click="closeFeedback">
            Close
          </v-btn>
          <v-btn
            class="blue white--text"
            text
            @click="sendEmail(itemFilteredTeams.name, itemFilteredTeams.class)"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CircularProgress v-if="showProgress" />
  </v-row>
</template>

<script>
import emailjs from "emailjs-com";
import CircularProgress from "@/components/CircularProgress.vue";
export default {
  props: ["itemFilteredTeams", "sport", "state"],
  data() {
    return {
      errorOnSubmit: false,
      showProgress: false,
      name: "",
      email: "",
      message: "",
      emailError: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      showFeedback: true,
      type: "",
    };
  },
  components: { CircularProgress },
  methods: {
    async sendEmail(teamName, classLevel) {
      this.showProgress = true;
      this.errorOnSubmit = false
      if(!this.email){
        this.errorOnSubmit = true
        return;
      }
      this.emailError = "";
      try {
        const response = await emailjs.send(
          "service_l53ch69",
          "template_wuefhgh",
          {
            name: this.name,
            message: this.message,
            email: this.email,
            type: this.type,
            teamName: teamName,
            sport: this.sport,
            state: this.state,
            class: classLevel,
          },
          "o90X7NobdeU4umK9U"
        );
        this.showProgress = false;
        this.showFeedback = false;
        // console.log(response.status, response.text)
        this.$emit("OK");
      } catch (error) {
        this.emailError = error;
      }
    },
    closeFeedback() {
      this.showFeedback = false;
      this.$emit("OK");
    },
  },
};
</script>
