import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c(VOverlay,[_c(VCard,{staticClass:"mx-auto",attrs:{"color":"white","max-width":"350"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"blue darken-3",attrs:{"dark":""}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between mt-3"},[_c('div',[_c(VCardSubtitle,[_vm._v(" Please select a different \"Class\" or increase the \"Distance\"... then SEARCH again... ")])],1)])])],1)],1),_c(VRow,{staticClass:"mb-1 mt-5"},[_c(VBtn,{staticClass:"ma-auto",attrs:{"rounded":"","color":"black","dark":""},on:{"click":_vm.closeDialog}},[_vm._v(" OK ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }