<template>
  <div>
    <v-dialog v-model="dialogModel" persistent>
      <!-- <v-card-title class="font-weight-bold" color="white"
      >Claim Your Team</v-card-title
    > -->
    <v-simple-table dark fixed-header height="600px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left font-weight-bold subtitle-1 blue darken-3 white--text">
              <v-row class="ml-2 my-6">
                <v-col>
                  <v-row>
                    TFL Summer Championship
                  </v-row>
                <v-row>
                    Text us at 469-525-0450
                </v-row>
                </v-col>
              </v-row>
            </th>
            <th class="text-left font-weight-bold subtitle-1 blue darken-3 white--text">
              
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in teams" :key="item.name">
            <td>{{ item.name }}</td>
            <td>
              <v-row class="my-4">
                <v-col>
                  <p
                    v-if="item.email && item.email.substring(0,4) !== 'UZU-'"
                    class="font-weight-medium my-auto blue--text darken-5"
                  >
                    CLAIMED
                  </p>
                  <v-btn
                    v-if="!item.email || item.email.substring(0,4) === 'UZU-'"
                    color="primary"
                    @click="execClaim(item)"
                    rounded
                    >Claim</v-btn
                  >
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <LoginClaim v-if="showLoginClaim" @registerClaim="funcRegister" @closeLogin="funcCloseLogin" @ccloseLogin="showLoginClaim=false"/>
    <RegisterClaim v-if="showRegisterClaim" @closeRegister="funcCloseLogin" @back="backRegister" @ccloseRegister="showRegisterClaim=false"/>
    <ModalPostClaim v-if="showPostClaim" />
    <ModalClaimNotice
      v-if="showClaim"
      :itemUpd="itemFilteredTeams"
      @OK="postClaim(itemFilteredTeams)"
      @Decline="showClaim = false"
    />
    <ModalPreClaim v-if="showPreClaim" />
    </v-dialog>
  </div>
</template>
<script>
import ModalClaimNotice from "./ModalClaimNotice.vue";
import LoginClaim from "./LoginClaim.vue";
import RegisterClaim from "./RegisterClaim.vue";
import ModalPostClaim from "./ModalPostClaim.vue";
import ModalPreClaim from "./ModalPreClaim.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogModel: true,
      showRegisterClaim: false,
      showLoginClaim: false,
      itemFilteredTeams: [],
      index: 0,
      showPreClaim: true,
      showPostClaim: false,
      showClaim: false,
      teams: [],
      search: "",
      headers: [
        { text: "Team", value: "name" },
        // { text: 'Email', value: 'email' }
      ],
    };
  },
  components: {
    ModalClaimNotice,
    ModalPostClaim,
    ModalPreClaim,
    LoginClaim, RegisterClaim
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.getTourneyClaim();
  },
  methods: {
    // updateTbl(){
    //   console.log(this.index)
    //   this.teams[this.index].email = this.user.username
    // },
    backRegister(){
      this.showRegisterClaim = false
      this.showLoginClaim = true
    },
    funcCloseLogin(){
        this.itemFilteredTeams = {
          ...this.teams[this.index],
          selectedInfo: "Claim",
          phoneNumber: this.user.attributes.phone_number,
          showPhone: this.user.attributes.nickname,
        };
      this.showLoginClaim = false
      this.showRegisterClaim = false
      this.showClaim = true
    },
    funcRegister(){
      this.showLoginClaim = false
      this.showRegisterClaim = true
    },
    async updateTourneyClaim(item) {
      const url =
        "https://4n2ndkkayh2uknhxywo3ob5byy0vyapc.lambda-url.us-east-1.on.aws/"; // s4t-search-updTourneyClaim
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const claimedItem = {
        rangeKey: item.rangeKey,
        email: this.user.username,
      };

      var raw = JSON.stringify(claimedItem);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        await fetch(url, requestOptions);
      } catch (err) {
        this.errorOnSubmit = err.message;
      }
      this.showProgress = false;
    },
    postClaim(item) {
      this.updateTourneyClaim(item);
      // this.updateTbl()
      this.showClaim = false;
      this.showPostClaim = true;
    },
    async execClaim(item) {
      this.index = this.teams.indexOf(item);
      if (this.user) {
        this.showClaim = true;
        this.itemFilteredTeams = {
          ...item,
          selectedInfo: "Claim",
          phoneNumber: this.user.attributes.phone_number,
          showPhone: this.user.attributes.nickname,
        };
      } else {
        this.showLoginClaim = true;
      }
    },
    async getTourneyClaim() {
      this.showProgress = true;

      const url =
        "https://gtgjnqtfvwsu2khxbhcdj4rmoa0gzkrm.lambda-url.us-east-1.on.aws/"; // s4t-search-getTourneyClaim
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
      };

      try {
        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            result.forEach((item) => {
              const coords = JSON.parse(item.geoJson);

              this.teams.push({
                ...item,
                coords: {
                  lat: coords.coordinates[1],
                  lng: coords.coordinates[0],
                },
              });
            });
            this.teams = this.teams.sort((a,b) => a.name.localeCompare(b.name))
          });
      } catch (err) {
        console.log(err);
      }
      this.showProgress = false;
    },
  },
};
</script>
