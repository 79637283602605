import { Auth } from "aws-amplify";

export const auth = {
  namespaced: true,
  state: {
    user: null,
    byears: [
      2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014,
      2015, 2016, 2017, 2018, 2019, 2020,
    ],
    bbStates: [
      // { text: "AL", inactive: false },
      { text: "AR", inactive: false },
      // { text: "AZ", inactive: false },
      { text: "CA", inactive: false },
      // { text: "CO", inactive: false },
      // { text: "DE", inactive: false },
      { text: "FL", inactive: false },
      { text: "GA", inactive: false },
      // { text: "IA", inactive: false },
      { text: "IL", inactive: false },
      { text: "IN", inactive: false },
      // { text: "KS", inactive: false },
      // { text: "KY", inactive: false },
      { text: "LA", inactive: false },
      // { text: "MD", inactive: false },
      // { text: "MI", inactive: false },
      // { text: "MO", inactive: false },
      // { text: "MS", inactive: false },
      // { text: "NC", inactive: false },
      // { text: "NE", inactive: false },
      // { text: "NJ", inactive: false },
      // { text: "NM", inactive: false },
      // { text: "NV", inactive: false },
      // { text: "NY", inactive: false },
      { text: "OH", inactive: false },
      { text: "OK", inactive: false },
      // { text: "PA", inactive: false },
      // { text: "SC", inactive: false },
      // { text: "TN", inactive: false },
      { text: "TX", inactive: false },
      // { text: "UT", inactive: false },
      // { text: "VA", inactive: false },
      // { text: "WV", inactive: false },
    ],
    bbAgeGroup: [
      "4U",
      "5U",
      "6U",
      "7U",
      "8U",
      "9U",
      "10U",
      "11U",
      "12U",
      "13U",
      "14U",
      "15U",
      "16U",
      "17U",
      "18U",
    ],
    bbClassLevel: [
      // "",
      "Major",
      "AAA",
      "AA",
      "A",
      // "O",
      // "All-Star",
      // "Coach Pitch A",
      // "Coach Pitch AA",
      // "Coach Pitch AAA",
      // "Coach Pitch All-Star",
      "HS",
      // "Kid Pitch A",
      // "Kid Pitch AA",
      // "Kid Pitch AAA",
      // "Kid Pitch Major",
      // "League",
      // "League Coach Pitch",
      // "Machine Pitch A",
      // "Machine Pitch AA",
      // "Machine Pitch AAA",
      // "Showcase"
    ],
    fpStates: [
      // { text: "AL", inactive: false },
      { text: "AR", inactive: false },
      // { text: "AZ", inactive: false },
      { text: "CA", inactive: false },
      // { text: "CO", inactive: false },
      // { text: "DE", inactive: false },
      { text: "FL", inactive: false },
      { text: "GA", inactive: false },
      // { text: "IA", inactive: false },
      { text: "IL", inactive: false },
      { text: "IN", inactive: false },
      // { text: "KS", inactive: false },
      // { text: "KY", inactive: false },
      { text: "LA", inactive: false },
      // { text: "MD", inactive: false },
      // { text: "MI", inactive: false },
      // { text: "MO", inactive: false },
      // { text: "MS", inactive: false },
      // { text: "NC", inactive: false },
      // { text: "NE", inactive: false },
      // { text: "NJ", inactive: false },
      // { text: "NM", inactive: false },
      // { text: "NV", inactive: false },
      // { text: "NY", inactive: false },
      { text: "OH", inactive: false },
      { text: "OK", inactive: false },
      // { text: "PA", inactive: false },
      // { text: "SC", inactive: false },
      // { text: "TN", inactive: false },
      { text: "TX", inactive: false },
      // { text: "UT", inactive: false },
      // { text: "VA", inactive: false },
      // { text: "WV", inactive: false },
    ],
    fpAgeGroup: ["4U","6U", "8U", "10U", "12U", "14U", "16U", "18U"],
    fpClassLevel: ["A", "B", "C", "HS"],
    os: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setOs(state, payload) {
      state.os = payload;
    },
  },
  actions: {
    async logout({ commit }) {
      commit("setUser", null);
      return await Auth.signOut();
    },
    findOS({ commit }) {
      try {
        var userAgent = window.navigator.userAgent,
          platform =
            window.navigator?.userAgentData?.platform ||
            window.navigator.platform,
          macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
          windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
          iosPlatforms = ["iPhone", "iPad", "iPod"],
          fbPlatforms = ["FBAN", "FBAV", "FB-IAB"];

        let os;
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = "Mac OS";
        } else if(navigator.userAgent.match(/FBAN|FBAV/i)){
            os = "Facebook"
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = "iOS";
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = "Windows";
        } else if (/Android/.test(userAgent)) {
          os = "Android";
        } else if (/Linux/.test(platform)) {
          os = "Linux";
        } 
        commit("setOs", os);
        return Promise.resolve("Success");
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async login({ commit }, { username, password }) {
      try {
        await Auth.signIn({
          username,
          password,
        });

        const userInfo = await Auth.currentUserInfo();
        commit("setUser", userInfo);
        return Promise.resolve("Success");
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async confirmSignUp(_, { username, code }) {
      try {
        await Auth.confirmSignUp(username, code);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async resendSignUp(_, { username }) {
      try {
        await Auth.resendSignUp(username);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async forgotPwdEmail(_, { username }) {
      try {
        await Auth.forgotPassword(username);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async forgotSubmit(_, { username, password, code }) {
      try {
        await Auth.forgotPasswordSubmit(username, code, password);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async signUp(_, { username, password, email, phone_number, nickname }) {
      try {
        await Auth.signUp({
          username,
          password,
          attributes: {
            email,
            phone_number,
            nickname,
          },
        });
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    async authAction({ commit }) {
      const userInfo = await Auth.currentUserInfo();
      commit("setUser", userInfo);
    },
  },
  getters: {
    user: (state) => state.user,
    bbStates: (state) => state.bbStates,
    bbAgeGroup: (state) => state.bbAgeGroup,
    bbClassLevel: (state) => state.bbClassLevel,
    fpStates: (state) => state.fpStates,
    fpAgeGroup: (state) => state.fpAgeGroup,
    fpClassLevel: (state) => state.fpClassLevel,
    byears: (state) => state.byears,
    os: (state) => state.os,
  },
};
