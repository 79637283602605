import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"color":"white","min-width":"300"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"blue darken-3",attrs:{"dark":""}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between mt-3"},[_c('div',[_c(VCardTitle,[_vm._v("Step 1 - Login or Register")]),_c(VCardSubtitle,[_vm._v(" Your email will be seen by parents. You can \"Allow\" potential parents to see your phone number during registration. ")]),_c(VCardTitle,[_vm._v("Step 2 - Claim Team")]),_c(VCardSubtitle,[_vm._v(" By claiming your team, you're able to edit your team's data. Once logged in, search for your team. If not found, pls search using a different \"Class\" in case it was classified incorrectly. Once found, click on the corresponding \"Info\" button and then click \"Claim\". If you don't see the \"Claim\" option, it may have been claimed already OR you need to click \"Search\" again to refresh the screen. If there was an illegal claim, please contact us. ")]),_c(VCardTitle,[_vm._v("Step 3 - Add Team")]),_c(VCardSubtitle,[_vm._v(" Please make sure that the team you want to add is not already in the system or else there will be duplicates and cause parent confusion. If you don't find your team, please click \"Add Team\" in the top right coaches' corner to add your team. ")]),_c(VCardTitle,[_vm._v("Step 4 - Edit Team")]),_c(VCardSubtitle,[_vm._v(" If you want to see your teams , click \"My Teams\" in the top right coaches' corner. On this page, you can modify, delete, and unclaim any team's data using the \"Actions\" column of the table. ")])],1)])])],1)],1),_c(VRow,{staticClass:"mb-1 mt-5"},[_c(VBtn,{staticClass:"ma-auto",attrs:{"to":"/","rounded":"","color":"black","dark":""}},[_vm._v(" OK ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }