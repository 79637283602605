/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMyTeams = /* GraphQL */ `
  query GetMyTeams($id: ID!) {
    getMyTeams(id: $id) {
      id
      name
      class
      email
      tblName
      year
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMyTeams = /* GraphQL */ `
  query ListMyTeams(
    $filter: ModelMyTeamsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        class
        email
        tblName
        year
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTrackChanges = /* GraphQL */ `
  query GetTrackChanges($id: ID!) {
    getTrackChanges(id: $id) {
      id
      email
      action
      tblName
      year
      lat
      lng
      name
      address
      class
      coach
      teamID
      tryout
      tryoutDate
      pickup
      pickupDate
      teamURL
      zip
      checked
      byear
      misc
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTrackChanges = /* GraphQL */ `
  query ListTrackChanges(
    $filter: ModelTrackChangesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrackChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        action
        tblName
        year
        lat
        lng
        name
        address
        class
        coach
        teamID
        tryout
        tryoutDate
        pickup
        pickupDate
        teamURL
        zip
        checked
        byear
        misc
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const myTeamsByEmailAndTblName = /* GraphQL */ `
  query MyTeamsByEmailAndTblName(
    $email: String!
    $tblName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelmyTeamsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    myTeamsByEmailAndTblName(
      email: $email
      tblName: $tblName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        class
        email
        tblName
        year
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const trackChangesByEmailnAction = /* GraphQL */ `
  query TrackChangesByEmailnAction(
    $email: String!
    $action: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeltrackChangesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    trackChangesByEmailnAction(
      email: $email
      action: $action
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        action
        tblName
        year
        lat
        lng
        name
        address
        class
        coach
        teamID
        tryout
        tryoutDate
        pickup
        pickupDate
        teamURL
        zip
        checked
        byear
        misc
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
