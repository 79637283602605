<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog v-model="showDisclaimer" persistent max-width="600">
        <v-card>
          <v-toolbar color="primary" dark>Disclaimer</v-toolbar>
          <v-card-text>
            <div class="text-h6 pa-8">
              <h5>
                We aim to have accurate information. However, there may be data
                here that's not up-to-date. All of our data came from public
                internet. We'd really appreciate it that if you find inaccurate data, you'd click
                on the corresponding "Report" option for that team and let us know. Before taking
                any actions, it would be optimal to reach out to the coach and get
                confirmation.
              </h5>
              <v-card-actions class="justify-end">
                <v-btn color="primary" @click="okDisclaimer">OK</v-btn>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      showDisclaimer: true,
    };
  },
  methods: {
    okDisclaimer() {
      this.showDisclaimer = false;
      this.$router.push('/')
      this.$emit("OK");
    },
  },
};
</script>
