<template>
  <v-dialog v-model="showProgress" persistent max-width="400px">
    <v-overlay>
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    showProgress: true,
  }),
};
</script>
