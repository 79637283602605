import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import MTeams from "../components/MTeams.vue";
import Login from "../components/Login.vue"
import { Auth } from 'aws-amplify';
import Disclaimer from '@/components/ModalDisclaimer.vue'
import ContactUs from '@/components/ModalContactUs.vue'
import AboutUs from '@/components/ModalAboutUs.vue'
import Suggestion from '@/components/ModalSuggestion.vue'
import Register from '@/components/Register.vue'
import MTManual from '@/components/MTManual.vue'
import ModalInstructions from '@/components/ModalInstructions.vue'
import ModalAddWarning from '@/components/ModalAddWarning.vue'
import Claim from '@/components/Claim'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/instructions",
    name: "instructions",
    component: ModalInstructions,
  },
  {
    path: "/claim",
    name: "claim",
    component: Claim,
  },
  {
    path: "/addwarning",
    name: "addwarning",
    component: ModalAddWarning,
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: Disclaimer,
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: AboutUs,
  },
  {
    path: "/contactus",
    name: "contactus",
    component: ContactUs,
  },
  {
    path: "/suggestion",
    name: "suggestion",
    component: Suggestion,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/mteams",
    name: "MTeams",
    component: MTeams,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/mtmanual",
    name: "mtmanual",
    component: MTManual,
    meta: { requiresAuth: true }
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = await Auth.currentUserInfo();

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next()
  }

})

export default router;
