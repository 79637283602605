import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c(VOverlay,[_c(VCard,{staticClass:"mx-auto",attrs:{"color":"white","max-width":"350"}},[_c(VSystemBar,{attrs:{"color":"pink darken-2","dark":""}}),_c(VAppBar,{attrs:{"dark":"","color":"pink"}},[_c(VToolbarTitle,[_vm._v("Claim a Team")])],1),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"color":"pink darken-2"}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Assign this team to me... ")]),_c(VCardSubtitle,{staticClass:"mt-0"},[_vm._v(" Team: "+_vm._s(_vm.itemUpd.name)+" "),_c('br'),_vm._v(" Coach: "+_vm._s(_vm.itemUpd.coach)+" "),_c('br'),_vm._v(" Location: "+_vm._s(_vm.itemUpd.address)+" "),_c('br')]),_c(VCardSubtitle,[_vm._v(" I understand that by clicking \"Accept\" below, I am providing my digital signature that I am taking full responsibility for the accuracy of this team's data. ")])],1)])])],1)],1),_c(VRow,{attrs:{"justify":"center"}},[(_vm.errorOnSubmit)?_c(VAlert,{staticClass:"mx-10 mb-4 mt-4",attrs:{"outlined":"","type":"warning","prominent":"","border":"left","dark":"","color":"error"}},[_vm._v(_vm._s(_vm.errorOnSubmit)+" ")]):_vm._e()],1),_c(VRow,{staticClass:"mb-3 mt-5"},[_c(VBtn,{staticClass:"ma-auto",attrs:{"rounded":"","color":"black","dark":""},on:{"click":_vm.declineClaim}},[_vm._v(" DECLINE ")]),_c(VBtn,{staticClass:"ma-auto",attrs:{"rounded":"","color":"black","dark":""},on:{"click":_vm.claimTeam}},[_vm._v(" Accept ")])],1)],1)],1),(_vm.showProgress)?_c('CircularProgress'):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }